import React, { useEffect, useState, useRef } from "react";
import "./audit-detail.scss";
import Header from "../../../layouts/Header";
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, CategoryScale } from 'chart.js';
import { Badge, Button, Card, Col, Row, Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import CaseData from "./pages/case-data";
import UploadedDocument from "./pages/uploaded-documents";
//import ATAScore from "./pages/ATAScore/ata-score";
import ChangeLogModal from "./pages/change-log";
import { MapAuditFormErrorList } from "../../../application/action/projectAction";
import { GetAuditChangeLog, GetAuditDetails, GetAuditIdSuccess, GetClosedCasesList, GetOpenCasesList, MapAuditError, PerformAudit } from "../../../application/action/auditAction";
import { useDispatch, useSelector } from "react-redux";
import { getAuditDetails, getAuditErrors, getMapAuditFormErrorsList, getUserDetail, getUserList } from "../../../application/selector/indexSelector";
import { ErrorCategoryList } from "../../../application/action/errorAction";
import Environment from "../../../infrastructure/core/Environment";
import useAuth from "../../../hooks/useAuth";
import { format } from "date-fns";
import jwtInterceptor from "../../../infrastructure/core/helpers/jwtInterceptor";
import { GetAllUsers, GetUserDetails } from "../../../application/action/userAction";
import LoadingBar from 'react-top-loading-bar';
import AuditScore from "./pages/AuditScore/create-audit-score";

export default function CreatAudit() {
    const [showChangeLog, setShowChangeLog] = useState('');
    const [auditorComments, setAuditorComments] = useState('');
    const [supervisorComments, setSupervisorComments] = useState(false);
    const [markedErrors, setMarkedErrors] = useState([]);
    const [IsAccepted, setIsAccepted] = useState(true);

    // HANDLE CHANGE LOG 
    const handleChangeLog = (state) => {
        setShowChangeLog(state);
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { auditId } = useParams();
    const { auth } = useAuth();
    const UsersList = useSelector(getUserList)
    const userData = useSelector(getUserDetail)
    const AuditDetailData = useSelector(getAuditDetails)
    const ErrorsList = useSelector(getMapAuditFormErrorsList) || [];
    const MappedErrors = useSelector(getAuditErrors) || [];
    console.log('MappedErrorsRaw', MappedErrors);
    // HANDLE ERRPR CHANGES FUNCTION
    const handleErrorChanges = (data) => {
        setMarkedErrors(data)
    }

    // USEEFFECT
    useEffect(() => {
        if (UsersList !== undefined) {
            const agentId = UsersList?.find(data => data.fullName === (AuditDetailData?.displayColumns?.find(col => col.colName === 'EMPLOYEE_NAME').colValue))?.id
            if (agentId !== undefined) { dispatch(GetUserDetails(agentId)) }
        }
    }, [UsersList, AuditDetailData])

    // USEEFFECT
    useEffect(() => {
        if (auditId !== undefined) {
            dispatch(GetAuditDetails(auditId))
            dispatch(GetAuditChangeLog(auditId))
            dispatch(ErrorCategoryList())
            dispatch(GetAllUsers())
        }
    }, [dispatch, auditId])

    // USEEFFECT
    useEffect(() => {
        if (auditId !== undefined) {
            dispatch(GetAuditChangeLog(auditId))
            setMarkedErrors(MappedErrors.errorsList)
        }
    }, [auditId, MappedErrors])

    // USEEFFECT
    useEffect(() => {
        if (AuditDetailData?.formId !== undefined) {
            dispatch(MapAuditFormErrorList(AuditDetailData?.formId))
            setAuditorComments(AuditDetailData?.overAllComment)
        }
    }, [AuditDetailData])

    // REMAINING ERRORS
    const remainingErrors = ErrorsList?.flatMap(category =>
        category.subErrorCategory.flatMap(sub =>
            sub.errorType.filter(error =>
                markedErrors?.some(mapped => mapped.errorId === error.id)
            )
        )
    ) || [];

    // Calculate total and scored points
    const totalPoints = remainingErrors.reduce((acc, error) => acc + error.points, 0);
    const scoredErrors = ErrorsList?.flatMap(category =>
        category.subErrorCategory.flatMap(sub =>
            sub.errorType.filter(error =>
                markedErrors?.some(mapped => mapped.errorId === error.id && !mapped.met)
            )
        )
    ) || [];
    const scoredPoints = scoredErrors.reduce((acc, error) => acc + error.points, 0);
    const achievedPoints = totalPoints - scoredPoints;

    // Calculate percentage
    const percentage = totalPoints > 0 ? ((achievedPoints / totalPoints) * 100).toFixed(2) : 100; // Handle division by zero
    ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

    // CHART DATA
    const data = {
        labels: ['Good Points', 'Bad Points'],
        datasets: [
            {
                data: percentage > 0 ? [achievedPoints, scoredPoints] : [0, 1], // Ensure valid data structure
                backgroundColor: percentage > 0 ? ['#098911', '#e85353'] : ['#e85353', '#e85353'],
                borderWidth: 0,
            },
        ],
    };

    // Chart options
    const options = {
        cutout: '80%',
        rotation: -90,
        circumference: 180,
        responsive: true,
        plugins: {
            tooltip: { enabled: false },
            legend: { display: false },
        },
    };
    // HANDLE SUBMIT NEXT
    const handleSubmitNext = async () => {
        handleSubmit()

        const agentId = UsersList?.find(data => data.fullName === (AuditDetailData?.displayColumns?.find(col => col.colName === 'EMPLOYEE_NAME').colValue))?.id
        const auditData = {
            id: auditId,
            formId: AuditDetailData.formId,
            projectId: AuditDetailData.projectId,
            caseId: AuditDetailData.caseId,
            datasetName: AuditDetailData.projectName.replace(/ /g, "_"),
            agentId: agentId,
            supervisorId: userData?.supervisorId,
            expectedScore: totalPoints,
            finalScore: achievedPoints,
            auditScore: achievedPoints,
            ataScore: 0,
            rebuttalScore: 0,
            overAllRebuttal: 'NA',
            overAllAuditorComment: auditorComments,
            assignedTo: userData?.supervisorId,
            status: "2061156f-e154-4b21-80e6-641677224503",
            auditedBy: auth.id,
            ataBy: Environment.defaultValue,
            lmsCaseId :AuditDetailData.lmsCaseId
        };
        try {
            const response = await jwtInterceptor.post(`${Environment.AUDIT_URL}createAudit`, auditData, {
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    //   authorization: `Bearer ${storedToken}`,
                },
            }
            );
            navigate(`/QaAudit/manage`);

        } catch (err) {
            console.log(err);

        } finally {
            navigate(`/QaAudit/manage`);
        }
    }
    // HANDLE DRAFT CLOSE
    const handleDraftClose = async () => {
        handleSubmit()
        const agentId = UsersList?.find(data => data.fullName === (AuditDetailData?.displayColumns?.find(col => col.colName === 'EMPLOYEE_NAME').colValue)).id
        const auditData = {
            id: auditId,
            formId: AuditDetailData.formId,
            projectId: AuditDetailData.projectId,
            caseId: AuditDetailData.caseId,
            datasetName: AuditDetailData.projectName.replace(/ /g, "_"),
            agentId: agentId,
            supervisorId: userData?.supervisorId,
            expectedScore: totalPoints,
            finalScore: achievedPoints,
            auditScore: achievedPoints,
            ataScore: 0,
            rebuttalScore: 0,
            overAllRebuttal: 'NA',
            overAllAuditorComment: auditorComments,
            assignedTo: auth.id,
            status: "2061156f-e154-4b21-80e6-641677224502",
            auditedBy: auth.id,
            ataBy: Environment.defaultValue,
            lmsCaseId :AuditDetailData.lmsCaseId
        };
        try {
            const response = await jwtInterceptor.post(`${Environment.AUDIT_URL}createAudit`, auditData, {
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    //   authorization: `Bearer ${storedToken}`,
                },
            }
            );
            navigate(`/QaAudit/manage`);

        } catch (err) {
            console.log(err);

        }
    }
    // HANDLE SUBMIT
    const handleSubmit = () => {
        
        const formattedData = markedErrors?.filter(data => (data.met && data.mappingId) || (!data.met && !data.mappingId))
            .map((data) => ({
                id: data.mappingId || Environment.defaultValue,
                auditorComment: data.remark, // Include remark
                errorId: data.errorId,
                auditId: auditId,
                updatedBy: auth.id,
                addedBy: 'auditor',
                isRebutAccepted: data.accept || Environment.defaultValue
            }))
        if (formattedData.length !== 0) {
            dispatch(MapAuditError(formattedData));
        }
    };
    const handleSubmitClose = async () => {
        // handleSubmit()
        const agentId = UsersList?.find(data => data.fullName === (AuditDetailData?.displayColumns?.find(col => col.colName === 'EMPLOYEE_NAME').colValue))?.id
        const auditData = {
            id: auditId,
            formId: AuditDetailData.formId,
            projectId: AuditDetailData.projectId,
            caseId: AuditDetailData.caseId,
            datasetName: AuditDetailData.projectName.replace(/ /g, "_"),
            agentId: agentId,
            supervisorId: userData?.supervisorId,
            expectedScore: totalPoints,
            finalScore: achievedPoints,
            auditScore: AuditDetailData.auditorScore,
            ataScore: 0,
            rebuttalScore: achievedPoints,
            overAllRebuttal: AuditDetailData.overAllRebuttal,
            overAllAuditorComment: AuditDetailData.overAllComment,
            assignedTo: userData?.supervisorId,
            status: "2061156f-e154-4b21-80e6-641677224506",
            auditedBy: auth.id,
            ataBy: AuditDetailData.assignedTo,
            lmsCaseId :AuditDetailData.lmsCaseId
        };
        try {
            const response = await jwtInterceptor.post(`${Environment.AUDIT_URL}createAudit`, auditData, {
                method: "POST",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-Type": "application/json",
                    //   authorization: `Bearer ${storedToken}`,
                },
            }
            );
            navigate(`/QaAudit/manage`);

        } catch (err) {
            console.log(err);

        } finally {
            navigate(`/QaAudit/manage`);
        }
    }
    useEffect(() => {
        dispatch(GetOpenCasesList(auth.id));
        dispatch(GetAuditIdSuccess(Environment.defaultValue))
    }, [dispatch, auth.id]);

    // PROGRESS TOP LOADER
    const loadingBarRef = useRef(null);
    useEffect(() => {
        if (loadingBarRef.current) {
            // Start the loading bar
            loadingBarRef.current.continuousStart();

            // Simulate a load completion or manage dynamically
            const timer = setTimeout(() => {
                loadingBarRef.current.complete();
            }, 3000); // Adjust timing as needed

            // Cleanup timer
            return () => clearTimeout(timer);
        }
    }, []);
    return (
        <React.Fragment>
            <Header />
            <ChangeLogModal show={showChangeLog} closeFunction={handleChangeLog} />
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="main manage-item-page audit-detail-page ps-0 pe-0" style={{ paddingTop: "70px", backgroundColor: "#F6F8FA" }}>
                <div className="page-top-header d-md-flex align-items-center justify-content-between mb-4">
                    <div className="d-flex gap-2 align-items-center">
                        <Link to="/QaAudit/manage"><i className="ri-arrow-left-s-line fs-22"></i></Link>
                        <h3 className="mb-0 fw-semibold fs-18">{
                                            AuditDetailData?.displayColumns?.find(col => col.colName === 'LIMS_CASE_ID')?.colValue || 'N/A'
                                        }<Badge className="align-middle" bg="orange" pill>Ongoing</Badge></h3>
                    </div>
                    <div className="d-flex gap-2 top-header-bttns">
                        {/* {IsAccepted ? (
                            <>
                                <Button variant="success" className="text-uppercase" onClick={handleAccept}>ACCEPT AUDIT</Button>
                                <Button variant="danger" className="text-uppercase" onClick={handleReject}>REJECT & REBUTTAL</Button></>
                        ) : (<Button variant="primary" className="text-uppercase" onClick={handleSubmit}>Submit Audit</Button>)} */}
                        {AuditDetailData?.isATADone ? (<Button variant="dark" className="text-uppercase" onClick={handleSubmitClose}>Close Audit</Button>) : (<>
                            <Button variant="success" className="text-uppercase" onClick={handleSubmitNext}>Submit Audit</Button>
                            <Button variant="dark" className="text-uppercase" onClick={handleDraftClose} > Save & Close</Button></>)}

                    </div>
                </div>

                <Row className="m-0">
                    {/* LEFT AREA */}
                    <Col xl={3} lg={4}>
                        <Card className="card-one audit-details-card">
                            <Card.Body className="overflow-y custom-scroll">
                                <div>
                                    {/* DOUGHNUT CHART VIEW */}
                                    <div className="doughnut-chart-view" style={{ position: 'relative', width: '100%' }}>
                                        <Doughnut data={data} options={options} />
                                        <div className="doughnut-chart-value">
                                            <div className="chart-overall-score">Overall Score</div>
                                            <div className="chart-overall-score">{percentage} %</div>
                                        </div>
                                    </div>

                                    {/* AUDIT SCORE */}
                                    <div className="border d-flex align-items-center justify-content-between p-3 mb-4">
                                        <div>
                                            <h3 className="fs-16">Audit Score</h3>
                                        </div>
                                        <div>
                                            <b className="fs-16">{achievedPoints}/{totalPoints}</b>
                                        </div>
                                    </div>

                                </div>

                                {/* CASE DATA */}
                                <div className="mb-4">
                                    <CaseData />
                                </div>

                                {/* UPLOAD DOCUMENT  */}
                                <div>
                                    <UploadedDocument />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* RIGHT AREA */}
                    <Col xl={9} lg={8}>
                        <Card className="card-one h-auto mb-3">
                            <Card.Body>
                                <Row className="g-3">
                                    <Col xl md={4} xs={6}>
                                        <h6 className="fs-14 fw-bold mb-1">{AuditDetailData?.auditorName}</h6>
                                        <Form.Label className="mb-0">Auditor Name</Form.Label>
                                    </Col>
                                    <Col xl md={4} xs={6}>
                                        <h6 className="fs-14 fw-bold mb-1">{
                                            AuditDetailData?.displayColumns?.find(col => col.colName === 'EMPLOYEE_NAME')?.colValue || 'N/A'
                                        }</h6>
                                        <Form.Label className="mb-0">Employee Name</Form.Label>
                                    </Col>
                                    <Col xl md={4} xs={6}>
                                        <h6 className="fs-14 fw-bold mb-1">{
                                            AuditDetailData?.displayColumns?.find(col => col.colName === 'LIMS_CASE_ID')?.colValue || 'N/A'
                                        }</h6>
                                        <Form.Label className="mb-0">LMIS Case ID</Form.Label>
                                    </Col>
                                    <Col xl md={4} xs={6}>
                                        {AuditDetailData && (<h6 className="fs-14 fw-bold mb-1">{format(new Date(AuditDetailData?.createdOn), "dd MMM yyyy hh:mm a")}</h6>)}
                                        <Form.Label className="mb-0">Action Date</Form.Label>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>

                        <Card className="card-one audit-details-tabs-card">
                            <Card.Body className="overflow-y custom-scroll p-0" style={{ backgroundColor: "#f5f7f9" }}>
                                <AuditScore ErrorChanges={handleErrorChanges} setAuditorComments={setAuditorComments} setSupervisorComments={setSupervisorComments} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}