import React from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";

export default function CreateErrorCategories(props) {
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-30">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Error Categories</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col>
                            <Col md={10}>
                                <div className="mb-4">
                                    <Form.Label>Error Type</Form.Label>
                                    <Form.Select></Form.Select>
                                </div>

                                <div className="mb-4">
                                    <Form.Label>Modified Data</Form.Label>
                                    <Form.Control type="date" />
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button type="submit" variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Create Error Categories</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}