import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button, Offcanvas, Form, Row, Col, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getDataSourceList, getHeaderList, getLoading, getMapProjectColumnList, getTeamsList } from "../../../application/selector/indexSelector";
import useAuth from "../../../hooks/useAuth";
import Environment from "../../../infrastructure/core/Environment";
import { AddProject, MapProjectColumn } from "../../../application/action/projectAction";
import LoadingSpinner from "../../_common/LoadingSpinner";

export default function CreateProject(props) {
    const teamsList = useSelector(getTeamsList)
    const columnsList = useSelector(getHeaderList)
    const datasourceList = useSelector(getDataSourceList)
    const mappedColumnList = useSelector(getMapProjectColumnList);
    const dispatch = useDispatch()
    const loading = useSelector(getLoading)
    const [mappedColumns, setMappedColumns] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const handleAddColumns = () => {
        setMappedColumns((prevData) => {
            // Combine previous mapped columns with the new selections
            const updatedColumns = selectedColumns.map((option) => ({
                colId: option.value,
                colName: option.label,
                status: "da973bc9-b30e-447b-aa42-14dc7e0cdb01",
            }));
    
            // Avoid duplicates by filtering out pre-existing items
            const uniqueColumns = [
                ...prevData.filter((col) => !updatedColumns.find((opt) => opt.colId === col.colId)),
                ...updatedColumns,
            ];
    
            return uniqueColumns;
        });
        setSelectedColumns([]); // Optionally reset the selected options
    };
    // Remove column from the mapped columns list
    const removeCol = (item) => {
        setMappedColumns(mappedColumns.filter((team) => team.colId !== item.colId));
    };
    useEffect(() => {
        setMappedColumns(
            columnsList
                ?.filter((col) => col.status === "da973bc9-b30e-447b-aa42-14dc7e0cdb01")
                .map((data) => ({
                    colId: data.id,
                    colName: data.headerValue,
                    colHeader: data.headerId,
                    status: "da973bc9-b30e-447b-aa42-14dc7e0cdb01"
                }))
        );
    }, [columnsList]);
    
    const handleColChange = (selectedOptions) => {
        setSelectedColumns(selectedOptions); // Store selected options
    
        
    };


    const { auth } = useAuth();
    const initialState = {
        projectName: "",
        projectDescription: "",
        teamId: Environment.defaultValue,
        sourceId: Environment.defaultValue,
        empId: "",
        createdBy: auth.id,
        modifiedBy: auth.id
    }
    const [formData, setFormData] = useState(initialState);
    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleTeam = (event) => {
        const selectedTeamId = event.target.value;
        const selectedTeam = teamsList.find(team => team.id === selectedTeamId); // Find the selected team based on ID

        if (selectedTeam) {
            setFormData({
                ...formData,
                teamId: selectedTeam.id, // Update team ID
                projectName: selectedTeam.teamName // Update project name
            });
        }
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        const payload = {
            ...formData,
            status: "da973bc9-b30e-447b-aa42-14dc7e0cdb01",
            columns: mappedColumns.map((col) => ({
                colId: col.colId,
                colName: columnsList.find((colData) => colData.id === col.colId)?.headerId
            }))
        };

        dispatch(AddProject(payload));
    };
    useEffect(() => {
        if (loading.value == 'projectList' && !loading.loading) {
            props.closeFunction()
            setFormData(initialState)
        }
       
    }, [loading])


    // Filter out already mapped columns
    const availableColumns = columnsList?.filter(
        col => !mappedColumns?.some(mapped => mapped.colId === col.id)
    );

    const associatedDataOption = availableColumns?.map((col) => ({
        value: col.id,           // Using col.id for the value
        label: col.headerValue   // Assuming headerValue is a property of col
    }));

    return (
        <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="fs-16 text-dark">Create New Project</Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
                <Row className="mb-4">
                    <Col md={12}>
                        <div>
                            <Form.Label>Project Team</Form.Label>
                            <Form.Select className="wt-400" value={formData.teamId} onChange={handleTeam}>
                                <option value="">Select Team</option>
                                {teamsList?.map((team) => (
                                    <option key={team.id} value={team.id}>
                                        {team.teamName}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="mb-4">
                            <Form.Label>Description</Form.Label>
                            <Form.Control type="text" as="textarea" rows={4} placeholder="Enter Description" name="projectDescription" value={formData.projectDescription} onChange={handleInputChange} />
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col md={12}>
                        <div>
                            <Form.Label>Employee Code Column</Form.Label>
                            <Form.Select className="wt-400" name="empId" value={formData.empId} onChange={handleInputChange}>
                                <option >Select Employee Code Field</option>
                                {columnsList?.map((header) => (
                                    <option key={header.id} value={header.headerId}>
                                        {header.headerValue}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col md={12}>
                        <div>
                            <Form.Label>Data Source: </Form.Label>
                            <Form.Select className="wt-400" name="sourceId" value={formData.sourceId} onChange={handleInputChange}>
                                <option >Select Data Source</option>
                                {datasourceList?.map((source) => (
                                    <option key={source.id} value={source.id}>
                                        {source.datasourceName}
                                    </option>
                                ))}
                            </Form.Select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className="mb-4">
                            <Form.Label>Associated Data Columns</Form.Label>
                            <div className="d-flex gap-2 mb-4">
                                <Select
                                    className="wt-400"
                                    options={associatedDataOption}  // Dropdown options
                                    value={selectedColumns}            // Bind to selected teams state
                                    onChange={handleColChange}      // Update state on selection change
                                    isMulti={true}                  // Enable multi-select
                                    isSearchable={true}             // Enables searching in the dropdown
                                    placeholder="Select Columns"      // Updated placeholder
                                />
                                <Button type="button" variant="dark" onClick={handleAddColumns}>Include</Button>
                            </div>
                            <div>
                                <h6 className="fs-14 mb-2">Defaults fields</h6>
                                <div className="d-flex gap-2 align-items-center flex-wrap mb-3">
                                    {mappedColumns?.filter(item => item.status === "da973bc9-b30e-447b-aa42-14dc7e0cdb01").map((item, index) => (
                                        <Badge className="fs-14" bg="secondary" pill key={index}>
                                            <span>{item.colName}</span>
                                        </Badge>
                                    ))}
                                </div>

                                <div className="d-flex gap-2 align-items-center flex-wrap">
                                    {mappedColumns?.length === 0 ? (
                                        <h6 className="fs-14 text-center">No columns mapped</h6>
                                    ) : (
                                        mappedColumns?.filter(item => item.status !== "da973bc9-b30e-447b-aa42-14dc7e0cdb01").map((item, index) => (
                                            <Badge bg="primary" className="fs-14" key={index} pill>
                                                <span>{item.colName}</span>
                                                <Link className="text-white align-middle ms-1" onClick={() => removeCol(item)}>
                                                    <i className="ri-close-circle-line"></i>
                                                </Link>
                                            </Badge>
                                        ))

                                    )}
                                </div>

                            </div>
                        </div>
                    </Col>
                </Row>
            </Offcanvas.Body>

            <div className="offcanvas-footer justify-content-start">
                <Button
                    type="submit"
                    variant="primary"
                    className="fs-14 me-2 d-flex align-items-center"
                    onClick={handleSubmit}
                >
                    <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                    {loading.loading && loading?.value === "projectList" ? (
                        <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
                    ) : (
                        <span className="align-middle">Create Project</span>
                    )}

                </Button>
            </div>
        </Offcanvas>
    );
}
