import React from "react";
import { Button, Image, Row, Col } from "react-bootstrap";
import excelIcon from "../../../../assets/img/icons/excel.png"
import pdfIcon from "../../../../assets/img/icons/pdf.png"
import screenshortIcon from "../../../../assets/img/icons/screenshort.png";

export default function UploadedDocument() {
    return (
        <React.Fragment>
            <div className="d-flex align-items-center justify-content-between mb-4">
                <h4 className="main-title fs-14 mb-0">Uploaded Documents</h4>
                <Button variant="primary">Upload Document</Button>
            </div>
            <Row className="g-3 align-items-center">
                <Col md={4} sm={6}>
                    <div className="d-flex align-items-center gap-2">
                        <div>
                            <Image src={excelIcon} alt="icon" />
                        </div>
                        <div>
                            <h4 className="fs-14">Sample Excel File</h4>
                            <h6 className="fs-12 text-black-50">By: Aamir Sayyed</h6>
                        </div>
                    </div>
                </Col>
                <Col md={4} sm={6}>
                    <div className="d-flex align-items-center gap-2">
                        <div>
                            <Image src={screenshortIcon} alt="icon" />
                        </div>
                        <div>
                            <h4 className="fs-14">Schreenshot AMD</h4>
                            <h6 className="fs-12 text-black-50">By: Aamir Sayyed</h6>
                        </div>
                    </div>
                </Col>
                <Col md={4} sm={6}>
                    <div className="d-flex align-items-center gap-2">
                        <div>
                            <Image src={pdfIcon} alt="icon" />
                        </div>
                        <div>
                            <h4 className="fs-14">PDF File</h4>
                            <h6 className="fs-12 text-black-50">By: Aamir Sayyed</h6>
                        </div>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    )
}