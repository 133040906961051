import React, { useState } from "react";
import { Button, Offcanvas, Form, Table } from "react-bootstrap";
import ErrorsData from "../../../Json/audit/add-error-list.json";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getAuditErrors, getMapAuditFormErrorsList } from "../../../application/selector/indexSelector";
import Environment from "../../../infrastructure/core/Environment";
import { useParams } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { MapAuditError } from "../../../application/action/auditAction";

export default function AddErrorsModal(props) {
    const { auditId } = useParams();   
    const { auth } = useAuth();
    const dispatch = useDispatch();

    // Ensure MappedErrors is always an array
    const MappedErrors = useSelector(getAuditErrors) || [];
    const ErrorsList = useSelector(getMapAuditFormErrorsList) || [];

   // Ensure MappedErrors is an array of objects, not an object with errorsList property
const mappedErrorsArray = Array.isArray(MappedErrors.errorsList) ? MappedErrors.errorsList : MappedErrors;

const pendingErrors = ErrorsList.filter((err) => !mappedErrorsArray.some((mapped) => mapped.errorId === err.id));

    const errorsOption = pendingErrors.map((err) => ({
        value: err.id,
        label: err.errorName,
    }));

    const [selectedError, setSelectedError] = useState(null);
    const [addedError, setAddedError] = useState([]);

    // Add selected error to the table
    const addErrorToTable = () => {
        if (selectedError && !addedError.includes(selectedError)) {
            setAddedError([...addedError, { ...selectedError, auditorComment: "" }]);
            setSelectedError(null); // Reset the select field
        }
    };

    // Handle comment input change for each error
    const handleCommentChange = (index, comment) => {
        const updatedErrors = [...addedError];
        updatedErrors[index].auditorComment = comment;
        setAddedError(updatedErrors);
    };

    // Remove error from the table
    const removeError = (error) => {
        setAddedError(addedError.filter((item) => item.value !== error.value));
    };

    // Handle submit
    const handleSubmit = () => {
        const formattedData = addedError.map((data) => ({
            mappingId: data.id || Environment.defaultValue,
            auditorComment: data.auditorComment,
            errorId: data.value,
            auditId: auditId,
            updatedBy: auth.id,
        }));
        
        dispatch(MapAuditError(formattedData));
        setAddedError([]);
        setSelectedError(null);
        props?.closeFunction();
    };
    const [selectedErrors, setSelectedErrors] = useState(new Set());

    const handleCheckboxChange = (error) => {
        const newSelectedErrors = new Set(selectedErrors);
        if (newSelectedErrors.has(error)) {
            newSelectedErrors.delete(error); // Deselect the error
        } else {
            newSelectedErrors.add(error); // Select the error
        }
        setSelectedErrors(newSelectedErrors);
    };

    const totalScore = Array.from(selectedErrors).reduce((total, error) => {
        const foundError = ErrorsData.find(e => e.error === error);
        return total + (foundError ? Number(foundError.score || 0) : 0);
    }, 0).toFixed(2);

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-80">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Add Error</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <div className="d-flex justify-content-end gap-2 mb-4">
                        <Select className="wt-400"
                            options={errorsOption}
                            value={selectedError}
                            onChange={setSelectedError}
                            isSearchable={true}
                            placeholder="Select Auditor" />
                        <Button type="button" variant="dark" onClick={addErrorToTable}>ADD</Button>
                    </div>

                    {addedError.length === 0 ? (

                        <div className="text-center border-0 pt-3 pb-3 mt-5" style={{backgroundColor: "#f4f4f4"}}>No errors added</div>
                    ) : (
                        addedError.map((item, index) => (
                            <div className="mb-3 p-3 add-error-lists" key={index}>
                                <Button variant="default" className="btn-icon position-absolute end-30" onClick={() => removeError(item)}>
                                    <i className="ri-close-line fs-18"></i>
                                </Button>
                                <div className="mb-2">
                                    <h5 className="fs-14">{item.label}</h5>
                                    <h6 className="fs-12">{item.category}</h6>
                                </div>
                                <Form.Control as="textarea" className="error-comment" placeholder="Comment..." />
                            </div>
                        ))
                    )}

                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button type="button" variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <span className="align-middle">Add Error</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    );
}
