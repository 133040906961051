export const GET_USERS_MAPPING_LIST = 'GET_USERS_MAPPING_LIST';
export const GET_USERS_MAPPING_LIST_SUCCESS = 'GET_USERS_MAPPING_LIST_SUCCESS';
export const UPLOAD_BRIDGE_DATA = 'UPLOAD_BRIDGE_DATA';
export const GET_ALL_AUDITOR = 'GET_ALL_AUDITOR';
export const GET_ALL_SUPERVISOR = 'GET_ALL_SUPERVISOR';
export const GET_ALL_SUPERVISOR_SUCCESS = 'GET_ALL_SUPERVISOR_SUCCESS';
export const GET_ALL_AUDITOR_SUCCESS = 'GET_ALL_AUDITOR_SUCCESS';
export const GET_ALL_ROLES = 'GET_ALL_ROLES';
export const GET_ALL_ROLES_SUCCESS = 'GET_ALL_ROLES_SUCCESS';
export const ADD_USER = 'ADD_USER';
export const ADD_USER_GROUP = 'ADD_USER_GROUP';
export const GET_ALL_USERGROUPS = 'GET_ALL_USERGROUPS';
export const GET_ALL_USERGROUPS_SUCCESS = 'GET_ALL_USERGROUPS_SUCCESS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS = 'GET_ALL_USERS';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';

export const UsersMappingList = () => ({
    type: GET_USERS_MAPPING_LIST
});
export const GetAllSupervisor = (data) => ({
    type: GET_ALL_SUPERVISOR,
    payload:data

});
export const GetAllAuditors = (data) => ({
    type: GET_ALL_AUDITOR,
    payload:data
});
export const UploadBridgeData = (data) => ({
    type: UPLOAD_BRIDGE_DATA,
    payload:data
});
export const UsersMappingListSuccess = (data) => ({
    type: GET_USERS_MAPPING_LIST_SUCCESS,
    payload:data
});
export const GetAllSupervisorSuccess = (data) => ({
    type: GET_ALL_SUPERVISOR_SUCCESS,
    payload:data
});
export const GetAllAuditorsSuccess = (data) => ({
    type: GET_ALL_AUDITOR_SUCCESS,
    payload:data
});
export const GetAllRoles = () => ({
    type: GET_ALL_ROLES,
});
export const GetAllRolesSuccess = (data) => ({
    type: GET_ALL_ROLES_SUCCESS,
    payload:data
});
export const AddUser = (data) => ({
    type: ADD_USER,
    payload:data

});
export const AddUserGroup = (data) => ({
    type: ADD_USER_GROUP,
    payload:data

});
export const GetAllUserGroups = () => ({
    type: GET_ALL_USERGROUPS,
});
export const GetAllUserGroupsSuccess = (data) => ({
    type: GET_ALL_USERGROUPS_SUCCESS,
    payload:data
});
export const GetAllUsers = () => ({
    type: GET_ALL_USERS,
});
export const GetAllUsersSuccess = (data) => ({
    type: GET_ALL_USERS_SUCCESS,
    payload:data
});
export const GetUserDetails = (id) => ({
    type: GET_USER_DETAILS,
    payload:id
});
export const GetUserDetailsSuccess = (data) => ({
    type: GET_USER_DETAILS_SUCCESS,
    payload:data
});