import { ADD_AUDITFORM, ADD_PROJECT, AuditFormListSuccess, GET_AUDITFORM_LIST, GET_AUDITFORM_LIST_BY_PROJID, GET_PROJECT_AUDIT_FORM_LIST_BYID, GET_PROJECT_LIST, GetAuditFormList, GetAuditFormListByProjIdSuccess, GetProjectAuditFormListByIdSuccess, GetProjectList, GetProjectListByAuditFormId, GetProjectListByAuditFormIdSuccess, MAP_AUDITFORM_COLUMN, MAP_AUDITFORM_ERROR, MAP_AUDITFORM_ERROR_TYPE, MAP_PROJECT_AUDIT_FORM, MAP_PROJECT_AUDITOR, MAP_PROJECT_COLUMN, MapAuditFormColumnSuccess, MapAuditFormErrorListSuccess, MAPPED_AUDITFORM_COLUMN_LIST_SUCCESS, MAPPED_AUDITFORM_ERROR_LIST, MAPPED_PROJECT_AUDIT_FORM_LIST, MAPPED_PROJECT_AUDITOR_LIST, MAPPED_PROJECT_COLUMN_LIST, MapProjectAuditFormListSuccess, MapProjectAuditorListSuccess, MapProjectColumnListSuccess, PROJECT_LIST_BY_AUDITFORM_ID, ProjectListSuccess, UNMAP_AUDITFORM_ERROR, UNMAP_AUDITFORM_ERROR_TYPE } from "../action/projectAction";
import * as Loading from "../action/loaderAction";
import { Navigate } from "react-router-dom";

const ProjectList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_PROJECT_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "projectList" }));
        
        const projectList = await api.projectAPI.ProjectList();
        dispatch(ProjectListSuccess(projectList));
        dispatch(Loading.setLoading({ loading: false, value: "projectList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

  const AddProject =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_PROJECT) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "projectList" }));
        const projectList = await api.projectAPI.AddProject(action.payload);
        dispatch(ProjectListSuccess(projectList));
        dispatch(Loading.setLoading({ loading: false, value: "projectList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const AddAuditForm =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === ADD_AUDITFORM) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "projectList" }));
        const auditformList = await api.projectAPI.AddAuditForm(action.payload);
        dispatch(AuditFormListSuccess(auditformList));
        dispatch(Loading.setLoading({ loading: false, value: "projectList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

  const AuditFormsList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_AUDITFORM_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "auditformList" }));
        
        const auditformList = await api.projectAPI.AuditFormList();
        dispatch(AuditFormListSuccess(auditformList));
        dispatch(Loading.setLoading({ loading: false, value: "auditformList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const GetAuditFormListByProjId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_AUDITFORM_LIST_BY_PROJID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "auditFormListByProjId" }));
        
        const auditFormListByProjId = await api.projectAPI.GetAuditFormListByProjId(action.payload);
        dispatch(GetAuditFormListByProjIdSuccess(auditFormListByProjId));
        dispatch(Loading.setLoading({ loading: false, value: "auditformList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

  const GetMapProjectAuditFormListById =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_PROJECT_AUDIT_FORM_LIST_BYID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "projectAuditFormlistById" }));
        
        const projectAuditFormlistById = await api.projectAPI.GetMapProjectAuditFormListById(action.payload);
        dispatch(GetProjectAuditFormListByIdSuccess(projectAuditFormlistById));
        dispatch(Loading.setLoading({ loading: false, value: "projectAuditFormlistById" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const MapProjectAuditForm =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MAP_PROJECT_AUDIT_FORM) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "projectAuditFormlistById" }));
        const projectAuditFormlistById = await api.projectAPI.MapProjectAuditForm(action.payload);
        dispatch(GetProjectAuditFormListByIdSuccess(projectAuditFormlistById));
        dispatch(Loading.setLoading({ loading: false, value: "projectAuditFormlistById" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
const MapProjectAuditor =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MAP_PROJECT_AUDITOR) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "projectAuditorList" }));
        
        const projectAuditorList = await api.projectAPI.MapProjectAuditor(action.payload);
        
        dispatch(MapProjectAuditorListSuccess(projectAuditorList));
        dispatch(Loading.setLoading({ loading: false, value: "projectAuditorList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

  const GetMapProjectAuditorList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MAPPED_PROJECT_AUDITOR_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "projectAuditorList" }));
        
        const projectAuditorList = await api.projectAPI.GetMapProjectAuditorList(action.payload);
        dispatch(MapProjectAuditorListSuccess(projectAuditorList));
        dispatch(Loading.setLoading({ loading: false, value: "projectAuditorList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
 
  const GetMapProjectAuditFormList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MAPPED_PROJECT_AUDIT_FORM_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "projectAuditFormlist" }));
        
        const projectAuditFormlist = await api.projectAPI.GetMapProjectAuditFormList(action.payload);
        dispatch(MapProjectAuditFormListSuccess(projectAuditFormlist));
        dispatch(Loading.setLoading({ loading: false, value: "projectAuditFormlist" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

  const MapProjectColumn =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MAP_PROJECT_COLUMN) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "projectColumnList" }));
        
        const projectColumnList = await api.projectAPI.MapProjectColumn(action.payload);
        dispatch(GetProjectList());
        dispatch(MapProjectColumnListSuccess(projectColumnList));
        dispatch(Loading.setLoading({ loading: false, value: "projectColumnList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

  const MapProjectColumnList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MAPPED_PROJECT_COLUMN_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "projectColumnList" }));
        
        const projectColumnList = await api.projectAPI.MapProjectColumnList(action.payload);
        dispatch(MapProjectColumnListSuccess(projectColumnList));
        dispatch(Loading.setLoading({ loading: false, value: "projectColumnList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const MapAuditFormColumnList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MAPPED_AUDITFORM_COLUMN_LIST_SUCCESS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "auditformColumnList" }));
        
        const auditformColumnList = await api.projectAPI.MapAuditFormColumnList(action.payload);
        dispatch(MapAuditFormColumnSuccess(auditformColumnList));
        dispatch(Loading.setLoading({ loading: false, value: "auditformColumnList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const MapAuditFormColumn =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MAP_AUDITFORM_COLUMN) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "auditformColumnList" }));
        
        const auditformColumnList = await api.projectAPI.MapAuditFormColumn(action.payload);
        dispatch(GetProjectList());
        dispatch(MapAuditFormColumnSuccess(auditformColumnList));
        dispatch(Loading.setLoading({ loading: false, value: "auditformColumnList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

  const MapAuditFormErrorList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MAPPED_AUDITFORM_ERROR_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "AuditFormErrorList" }));
        
        const AuditFormErrorList = await api.projectAPI.MapAuditFormErrorList(action.payload);
        dispatch(MapAuditFormErrorListSuccess(AuditFormErrorList));
        dispatch(Loading.setLoading({ loading: false, value: "AuditFormErrorList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const MapAuditFormErrors =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MAP_AUDITFORM_ERROR) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "AuditFormErrorList" }));
        
        const AuditFormErrorList = await api.projectAPI.MapAuditFormErrors(action.payload);
        dispatch(GetAuditFormList());
        dispatch(MapAuditFormErrorListSuccess(AuditFormErrorList));
        dispatch(Loading.setLoading({ loading: false, value: "AuditFormErrorList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const UnMapAuditFormErrors =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UNMAP_AUDITFORM_ERROR) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "AuditFormErrorList" }));
        
        const AuditFormErrorList = await api.projectAPI.UnMapAuditFormErrors(action.payload);
        dispatch(GetAuditFormList());
        dispatch(MapAuditFormErrorListSuccess(AuditFormErrorList));
        dispatch(Loading.setLoading({ loading: false, value: "AuditFormErrorList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

  const ProjectListByAuditFormId =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === PROJECT_LIST_BY_AUDITFORM_ID) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "MappedProjectList" }));
        
        const MappedProjectList = await api.projectAPI.ProjectListByAuditFormId(action.payload);
        dispatch(GetProjectListByAuditFormIdSuccess(MappedProjectList));
        dispatch(Loading.setLoading({ loading: false, value: "MappedProjectList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const UnMapAuditFormErrorType =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UNMAP_AUDITFORM_ERROR_TYPE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "AuditFormErrorList" }));
        
        const AuditFormErrorList = await api.projectAPI.UnMapAuditFormErrorType(action.payload);
        dispatch(MapAuditFormErrorListSuccess(AuditFormErrorList));
        dispatch(Loading.setLoading({ loading: false, value: "AuditFormErrorList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const MapAuditFormErrorType =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MAP_AUDITFORM_ERROR_TYPE) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "AuditFormErrorList" }));
        
        const AuditFormErrorList = await api.projectAPI.MapAuditFormErrorType(action.payload);
        dispatch(GetAuditFormList());
        dispatch(MapAuditFormErrorListSuccess(AuditFormErrorList));
        dispatch(Loading.setLoading({ loading: false, value: "AuditFormErrorList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  export default [
    ProjectList,AddProject,AuditFormsList,MapProjectAuditor,GetMapProjectAuditorList,MapProjectAuditForm,AddAuditForm,
    GetMapProjectAuditFormList,MapProjectColumn,GetAuditFormListByProjId,MapAuditFormErrorType,
    MapProjectColumnList,MapAuditFormColumn,MapAuditFormColumnList,GetMapProjectAuditFormListById,
    MapAuditFormErrorList,MapAuditFormErrors,UnMapAuditFormErrors,ProjectListByAuditFormId, UnMapAuditFormErrorType
  ]