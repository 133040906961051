const DashboardMenu = [
  // {
  //   "label": "Management Dashboard",
  //   "link": "dashboard/management-dashboard",
  //   "icon": "ri-line-chart-line"
  // },
  {
    "label": "TL Dashboard",
    "link": "dashboard/tl-dashboard",
    "icon": "ri-dashboard-line"
  },
  {
    "label": "Auditor Dashboard",
    "link": "dashboard/auditor-dashboard",
    "icon": "ri-user-search-line"
  }
];


const PendingResponseMenu = [
  {
    "label": "Pending Response",
    "link": "pendingResponse/manage",
    "icon": "ri-history-line",
    // "count": 5
  }
];
const  QASupervisorAudit =[
  {
    "label": "Audits",
    "link": "supervisorAudits/manage",
    "icon": "ri-history-line",
    // "count": 5
  }
];

const ReportAnayticsMenu = [
  {
    "label": "QA Audit",
    "link": "QaAudit/manage",
    "icon": "ri-award-fill",
    // "count": 8
  }
];

const InventoryMenu = [
  {
    "label": "Inventory",
    "link": "/inventory/manage",
    "icon": "ri-book-line"
  }
];

const ProjectsMenu = [
  {
    "label": "Project",
    "link": "/projects/manage",
    "icon": "ri-mail-settings-line"
  }
]

const AuditFormMenu = [
  {
    "label": "Audit Form",
    "link": "/auditform/manage",
    "icon": "ri-survey-line"
  }
]

const ReportMenu = [
  {
    "label": "Report",
    "link": "/report/manage",
    "icon": "ri-file-chart-line"
  }
]

const SystemSettingMenu = [
  {
    "label": "System Setting",
    "link": "/system-settings",
    "icon": "ri-tools-line"
  }
];



export { DashboardMenu, PendingResponseMenu, ReportAnayticsMenu, InventoryMenu, ProjectsMenu, AuditFormMenu, ReportMenu, SystemSettingMenu, QASupervisorAudit };