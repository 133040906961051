import React from "react";
import { Card } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getChangeLog } from "../../../../application/selector/indexSelector";
import { format } from "date-fns";

export default function ChangeLog() {
    const ChangesLog = useSelector(getChangeLog)

    return (
        <React.Fragment>
            <h3 className="fs-14 fw-semibold mb-3">Change Log</h3>
            <div className="activity-log-timeline">
                <ul className="activity-group">
                    {ChangesLog?.map((item, index) => (
                            <li className={`activity-item ${item.status === "success" ? "success-class" : "danger-class"}`} key={index}>
                                <h5 className="fs-14 mb-1">{item.updatedByName} ( {item.summary}) </h5>

                                {item.des && (
                                    <div className="product-wrapper mb-1">
                                        <Card.Body>{item.des}</Card.Body>
                                    </div>
                                )}

                                <h6 className="text-secondary fs-xs ms-auto">{format(new Date(item.updatedOn), "dd MMM yyyy hh:mm a") }</h6>
                            </li>
                        ))}
                </ul>
            </div>
        </React.Fragment>
    )
}