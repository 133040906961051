import React, { useEffect, useState, useRef } from "react";
import DataTable from "react-data-table-component";
import ToastifyService from "../../_common/ToastifyService";
import SweetAlertService from "../../_common/SweetAlertService";
//import userGroupData from "../../../Json/Setting/user-group.json";
import { Button, Card, Form } from "react-bootstrap";
import CreateUserGroup from "./create-user-group";
import { useDispatch, useSelector } from "react-redux";
import { GetAllUserGroups } from "../../../application/action/userAction";
import { getUserGroupList } from "../../../application/selector/indexSelector";
import LoadingBar from 'react-top-loading-bar';

export default function UserGroup() {
    const dispatch = useDispatch();
    const UserGroupList = useSelector(getUserGroupList)
    useEffect(() => {
        dispatch(GetAllUserGroups())
    }, []);

    // TABLE COLUMNS
    const columns = [
        {
            name: "User Group Name",
            selector: (row) => row.userGroupName,
            sortable: true,
        },

        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex">
                    <Button variant="primary" className="btn-icon me-2" onClick={() => handleUserGroup(true)}><i className="ri-pencil-line"></i></Button>
                    <Button variant="outline-danger" className="btn-icon" onClick={() => handleDelete(true)}><i className="ri-delete-bin-line"></i></Button>
                </div>
            ),
            width: "200px"
        }
    ];

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredUserGroup = UserGroupList?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    // HANDLE USER GROUP FUNCTION
    const [showUserGroup, setShowUserGroup] = useState(false);
    const handleUserGroup = (state) => {
        setShowUserGroup(state);
    }

    // HANDLE DELETE FUNCTION
    const [data, setData] = useState([]);
    const handleDelete = (index) => {
        SweetAlertService.showAlert(
            "User Group Name",
            "Are you sure you want to delete this User?",
            "warning"
        )
            .then((result) => {
                if (result.isConfirmed) {
                    ToastifyService.success(`User Deleted Successfully`);
                    const newData = [...data];
                    newData.splice(index, 1);
                    setData(newData);
                }
            })
            .catch((error) => {
                console.error("Error:", error);
                ToastifyService.error(`Something went wrong`);
            });
    };

     // PROGRESS TOP LOADER
     const loadingBarRef = useRef(null);
     useEffect(() => {
         if (loadingBarRef.current) {
             // Start the loading bar
             loadingBarRef.current.continuousStart();
     
             // Simulate a load completion or manage dynamically
             const timer = setTimeout(() => {
                 loadingBarRef.current.complete();
             }, 3000); // Adjust timing as needed
     
             // Cleanup timer
             return () => clearTimeout(timer);
         }
       }, []);

    return (
        <React.Fragment>
            <CreateUserGroup show={showUserGroup} closeFunction={handleUserGroup} />
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="fs-16">User Group</h4>
                </div>
                <div>
                    <Button variant="primary" onClick={() => handleUserGroup(true)}><i className="ri-add-line align-middle"></i> Create New User Group</Button>
                </div>
            </div>

            <Card className="card-one">
                <Card.Header className="align-items-center justify-content-between">
                    <h4 className="main-title fs-14 mb-0">List of User Group</h4>
                    <div className="custom-dropdown-wrapper">
                        <div className="custom-drop-down z-index-2 wt-300">
                            <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                            <Form.Control
                                type="text"
                                placeholder="Search..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    {filteredUserGroup && filteredUserGroup !== undefined && (
                        <DataTable
                            columns={columns}
                            data={filteredUserGroup}
                            fixedHeader
                            search={true}
                            highlightOnHover
                            pagination
                        ></DataTable>
                    )}

                </Card.Body>
            </Card>
        </React.Fragment>
    )
}