import React, { useEffect, useState } from "react";
import { Accordion, Button, Card, Form, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getErrorCategoryList, getErrorList, getMapAuditFormErrorsList, getSubErrorList } from "../../../application/selector/indexSelector";
import useAuth from '../../../hooks/useAuth';
import { MapAuditFormError, UnMapAuditFormError } from "../../../application/action/projectAction";
import Environment from "../../../infrastructure/core/Environment";
import ErrorModal from "./modal/error-modal";
import SubErrorModal from "./modal/sub-error-modal";
import { GetAuditErrorList, MapAuditError } from "../../../application/action/auditAction";

export default function AuditErrors(props) {
    const dispatch = useDispatch();
    const { auth } = useAuth();

    const MappedErrors = useSelector(getMapAuditFormErrorsList);
    const ErrorList = useSelector(getErrorList);
    const ErrorCategoryList = useSelector(getErrorCategoryList);


    const [selectedError, setSelectedError] = useState(null);
    const [addedErrors, setAddedErrors] = useState([]);
    const [selectedErrorCategory, setSelectedErrorCategory] = useState(null);

    const initialFormData = {
        formId: props?.data?.auditFormId,
        createdBy: auth.id,
        modifiedBy: auth.id,
        errorsArray: [], // Holds all errors
    };

    const [formData, setFormData] = useState(initialFormData);

    useEffect(() => {
        if (MappedErrors && MappedErrors.length > 0) {
            setAddedErrors(MappedErrors);
        }
    }, [MappedErrors]);


    const [activeKey, setActiveKey] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedSubCategory, setSelectedSubCategory] = useState('');
    const [mapping, setMapping] = useState('');
    const [currentErrorIndex, setCurrentErrorIndex] = useState(0);

    const errorCategories = [
        { id: 'error_1', title: 'Data Integrity Errors' },
        { id: 'error_2', title: 'Authorization & Security Errors' },
        { id: 'error_3', title: 'Identification & Matching Errors' },
        { id: 'error_4', title: 'Formatting & Validation Errors' },
        { id: 'error_5', title: 'Operational & Processing Errors' },
    ];

    const handleToggle = (key) => {
        setActiveKey(activeKey === key ? null : key); // Toggle the active state
    };


    const [showErrorModal, setShowErrorModal] = useState(false);
    const handleAddError = (data) => {
        setShowErrorModal(data)
    };

    const [showSubErrorModal, setShowSubErrorModal] = useState(false);
    const handleSubErrorModal = (data) => {
        setShowSubErrorModal(data)
    }
    const errorsOption = ErrorList?.map((err) => ({
        value: err.id,
        label: err.errorName
    }));
    const ErrorCategoryOption = ErrorCategoryList?.map((err) => ({
        value: err.id,
        label: err.errorCategoryName
    }));



    // Add a new error entry to the table and formData.errorsArray
    const addErrorToTable = () => {
        const newErrorEntry = {
            ...formData,
            errorId: selectedError?.value,
            errorCategoryId: selectedErrorCategory?.value,
        };
        setAddedErrors([...addedErrors, newErrorEntry]);
        // Clear selected fields after adding
        setSelectedError(null);
        setSelectedErrorCategory(null);

        // setFormData(updatedFormData); // Update formData with the new array
    };

    const handleSelectionChange = (index, field, value) => {
        const updatedErrors = [...addedErrors];

        // Update the specific error in addedErrors
        updatedErrors[index] = { ...updatedErrors[index], [field]: value };
        setAddedErrors(updatedErrors);

        // Create a copy of the existing errorsArray
        const updatedErrorsArray = [...formData.errorsArray];

        // Find the index of the modified error in formData.errorsArray using the errorId
        const errorIndexInFormData = updatedErrorsArray.findIndex(
            error => error.errorId === updatedErrors[index].errorId
        );
        if (errorIndexInFormData !== -1) {
            // If the error exists, update it in errorsArray
            updatedErrorsArray[errorIndexInFormData] = updatedErrors[index];
        } else {
            // If the error doesn't exist, add it to errorsArray
            updatedErrorsArray.push(updatedErrors[index]);
        }

        // Update formData with the modified errorsArray
        setFormData(prevFormData => ({
            ...prevFormData, [field]: value,
            errorsArray: updatedErrorsArray,
        }));
    };


    const removeError = (index, data) => {
        const updatedErrors = addedErrors.filter((_, idx) => idx !== index);
        setAddedErrors(updatedErrors);
        setFormData({
            ...formData,
            errorsArray: updatedErrors,
        });
        if (data.id !== undefined) {
            const Fomrmdata = {
                Id: data.id,
                formId: data.formId
            }
            dispatch(UnMapAuditFormError(Fomrmdata))
        }
    };

    const handleUpdateError = (e) => {
        e.preventDefault();
        const formattedData = formData.errorsArray.map(data => ({
            "mappingId": data.id || Environment.defaultValue,
            "errorId": data.errorId,
            "errorCategoryId": data.errorCategoryId,
            "formId": formData.formId,
            "isFatal": data.isFatal === true || data.isFatal === "true",
            "isMapToScore": data.isMapToScore === true || data.isMapToScore === "true",
            "createdBy": formData.createdBy,
            "modifiedBy": formData.modifiedBy
        }));

        dispatch(MapAuditFormError(formattedData));
        setFormData(initialFormData)
    };
    const handleRemoveError =(catData, subcatData, errorData)=>{
        const formattedData = [{
            "mappingId": errorData.id,
            "errorId": Environment.defaultValue,
            "errorCategoryId": catData.errorCategoryId,
            "subErrorCategoryId": subcatData.subErrorCatId,
            "formId": catData.formId,
            "Points": errorData.isMapToScore ,
            "createdBy": catData.createdBy,
            "modifiedBy": auth.id
        }];
        dispatch(MapAuditFormError(formattedData));
    }
    return (
        <React.Fragment>
            <ErrorModal show={showErrorModal} closeFunction={() => setShowErrorModal(false)} data={props?.data} onErrorAdd={handleSubErrorModal} selectedCategory={selectedCategory}  selectedSubCategory={selectedSubCategory}  mappingId={mapping} />
            <SubErrorModal show={showSubErrorModal} closeFunction={handleSubErrorModal} data={props?.data} onErrorAdd={handleAddError} selectedCategory={selectedCategory}mappingId={mapping}/>
            <Form>
                <h3 className="fs-14 mb-3">Add error opportunities</h3>
                {/* <Accordion className="error-opportunities-collapse" activeKey={activeKey} onSelect={setActiveKey}>
                    {addedErrors?.map((errorcat) => (
                        <Card key={errorcat.errorCategoryId} className={`card-one ${activeKey === errorcat.errorCategoryId ? 'active-collapse' : ''}`}>
                            <Card.Header>
                                <span>{errorcat.errorCategoryName}</span>
                                <div className="d-flex gap-2 align-items-center">
                                    <Button variant="dark" onClick={() => setShowErrorModal(true)}>Add Error</Button>
                                    <Button variant="outline-primary" className="btn-icon" onClick={() => handleToggle(errorcat.errorCategoryId)}>
                                        {activeKey === errorcat.errorCategoryId ? (
                                            <i className="ri-arrow-up-s-line fs-24"></i>
                                        ) : (
                                            <i className="ri-arrow-down-s-line fs-24"></i>
                                        )}
                                    </Button>
                                </div>
                            </Card.Header>
                            <Accordion.Collapse eventKey={errorcat.errorCategoryId}>
                                <Card.Body>
                                    {errorcat?.subErrorCategory?.map((errorsubcat) => (
                                        <Card className="card-one h-auto border">
                                        <Card.Header className="align-items-center justify-content-between ht-60" style={{ backgroundColor: "#f0f0f0" }}>
                                            <h3 className="fs-14">{errorsubcat.subErrorCatName}</h3>
                                            <Button variant="primary" onClick={() => handleSubErrorModal(true)}>Add Sub Error</Button>
                                        </Card.Header>
                                        <Card.Body>
                                            <Table>
                                                <Table className="mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th className="w-40">Error</th>
                                                            <th className="w-40">Points</th>
                                                            <th className="wt-200">Action</th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                    {errorsubcat?.errorType?.map((error) => (
                                                        <tr>
                                                            <td>{error.errorName}</td>
                                                            <td>{error.points}</td>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <Button variant="primary" className="btn-icon me-2"><i className="ri-pencil-line"></i></Button>
                                                                    <Button variant="outline-danger" className="btn-icon"><i className="ri-delete-bin-line"></i></Button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))} 
                                                    </tbody>
                                                </Table>
                                            </Table>
                                        </Card.Body>
                                    </Card>
                                    ))}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion> */}
                <Accordion className="error-opportunities-collapse" activeKey={activeKey} onSelect={setActiveKey}>
                    {ErrorCategoryList?.map((errorCategory) => (
                        <Card
                            key={errorCategory.id}
                            className={`card-one ${activeKey === errorCategory.id ? 'active-collapse' : ''}`}
                        >
                            <Card.Header>
                                <span>{errorCategory.errorCategoryName}</span>
                                <div className="d-flex gap-2 align-items-center">
                                    <Button
                                        variant="dark"
                                        onClick={() => {
                                            // const mappedError = addedErrors?.find(
                                            //     (mappedError) => mappedError.errorCategoryId === errorCategory.id
                                            // );
                                            
                                            // if (mappedError) {
                                            //     setShowSubErrorModal(true);
                                            //     setMapping(mappedError.id);
                                            //     setSelectedCategory(errorCategory.id)
                                            // } else {
                                                setShowSubErrorModal(true);
                                                setMapping(Environment.defaultValue);
                                                setSelectedCategory(errorCategory.id)
                                            // }
                                        }}
                                    >
                                        Add Sub Error
                                    </Button>
                                    <Button
                                        variant="outline-primary"
                                        className="btn-icon"
                                        onClick={() => handleToggle(errorCategory.id)}
                                    >
                                        {activeKey === errorCategory.id ? (
                                            <i className="ri-arrow-up-s-line fs-24"></i>
                                        ) : (
                                            <i className="ri-arrow-down-s-line fs-24"></i>
                                        )}
                                    </Button>
                                </div>
                            </Card.Header>
                            <Accordion.Collapse eventKey={errorCategory.id}>
                                <Card.Body>
                                    {addedErrors
                                        ?.filter((mappedError) => mappedError.errorCategoryId === errorCategory.id)
                                        ?.map((errorcat) =>
                                            errorcat?.subErrorCategory?.map((errorsubcat) => (
                                                <Card className="card-one h-auto border" key={errorsubcat.id}>
                                                    <Card.Header
                                                        className="align-items-center justify-content-between ht-60"
                                                        style={{ backgroundColor: "#f0f0f0" }}
                                                    >
                                                        <h3 className="fs-14">{errorsubcat.subErrorCatName}</h3>
                                                        <Button
                                                            variant="primary"
                                                            onClick={() => {
                                                                
                                                                setShowErrorModal(true);
                                                                 setSelectedCategory(errorcat.errorCategoryId);
                                                                 setSelectedSubCategory(errorsubcat.subErrorCatId);  
                                                                 setMapping(Environment.defaultValue);}}
                                                        >
                                                            Add Error
                                                        </Button>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Table>
                                                            <thead>
                                                                <tr>
                                                                    <th className="w-40">Error</th>
                                                                    <th className="w-40">Points</th>
                                                                    <th className="wt-200">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {errorsubcat?.errorType?.map((error) => (
                                                                    <tr key={error.id}>
                                                                        <td>{error.errorName}</td>
                                                                        <td>{error.points}</td>
                                                                        <td>
                                                                            <div className="d-flex">
                                                                                <Button variant="primary" className="btn-icon me-2" >
                                                                                    <i className="ri-pencil-line"></i>
                                                                                </Button>
                                                                                <Button variant="outline-danger" className="btn-icon" onClick={()=>handleRemoveError(errorcat,errorsubcat, error )}>
                                                                                    <i className="ri-delete-bin-line"></i>
                                                                                </Button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </Card.Body>
                                                </Card>
                                            ))
                                        ) || <p>No subcategories or errors available for this category.</p>}
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    ))}
                </Accordion>

            </Form>
        </React.Fragment>
    );
}
