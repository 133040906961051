export const GET_OPENCASES_LIST = 'GET_OPENCASES_LIST';
export const GET_OPENCASES_LIST_SUCCESS = 'GET_OPENCASES_LIST_SUCCESS';
export const GET_CLOSED_CASES_LIST = 'GET_CLOSED_CASES_LIST';
export const GET_CLOSED_CASES_LIST_SUCCESS = 'GET_CLOSED_CASES_LIST_SUCCESS';
export const GET_PENDING_RESPONSE_CASES_LIST = 'GET_PENDING_RESPONSE_CASES_LIST';
export const GET_PENDING_RESPONSE_CASES_LIST_SUCCESS = 'GET_PENDING_RESPONSE_CASES_LIST_SUCCESS';
export const GET_ONGOING_CASES_LIST = 'GET_ONGOING_CASES_LIST';
export const GET_ONGOING_CASES_LIST_SUCCESS = 'GET_ONGOING_CASES_LIST_SUCCESS';
export const GET_PENDING_CASES_LIST_SUCCESS = 'GET_PENDING_CASES_LIST_SUCCESS';
export const GET_PENDING_CASES_LIST = 'GET_PENDING_CASES_LIST';
export const GET_AUDIT_DETAILS = 'GET_AUDIT_DETAILS';
export const GET_AUDIT_DETAILS_SUCCESS = 'GET_AUDIT_DETAILS_SUCCESS';
export const GET_AUDIT_ERRORS_SUCCESS = 'GET_AUDIT_ERRORS_SUCCESS';
export const GET_AUDIT_CHANGE_LOG_SUCCESS = 'GET_AUDIT_CHANGE_LOG_SUCCESS';
export const GET_AUDIT_CHANGE_LOG = 'GET_AUDIT_CHANGE_LOG';
export const GET_AUDIT_ERRORS = 'GET_AUDIT_ERRORS';
export const MAP_AUDIT_ERRORS = 'MAP_AUDIT_ERRORS';
export const PERFORM_AUDIT = 'PERFORM_AUDIT';
export const GET_AUDIT_ID_SUCCESS = 'GET_AUDIT_ID_SUCCESS';
export const UPDATE_CASE_STATUS_SUCCESS = 'UPDATE_CASE_STATUS_SUCCESS';
export const GET_FINAL_CLOSED_CASES_LIST_SUCCESS = 'GET_FINAL_CLOSED_CASES_LIST_SUCCESS';
export const UPDATE_CASE_STATUS = 'UPDATE_CASE_STATUS';
export const UPDATE_AUDIT_ERROR_COMMENTS = 'UPDATE_AUDIT_ERROR_COMMENTS';
export const GET_FINAL_CLOSED_CASES_LIST = 'GET_FINAL_CLOSED_CASES_LIST';

export const GetOpenCasesList = (data) => ({
    type: GET_OPENCASES_LIST,
    payload: data
});
export const GetOpenCasesListSuccess = (data) => ({
    type: GET_OPENCASES_LIST_SUCCESS,
    payload:data
});
export const GetPendingCasesList = (data) => ({
    type: GET_PENDING_CASES_LIST,
    payload:data
});
export const GetPendingCasesListSuccess = (data) => ({
    type: GET_PENDING_CASES_LIST_SUCCESS,
    payload:data
});

export const GetClosedCasesList = (data) => ({
    type: GET_CLOSED_CASES_LIST,
    payload:data
});
export const GetClosedCasesListSuccess = (data) => ({
    type: GET_CLOSED_CASES_LIST_SUCCESS,
    payload:data
});
export const GetFinalClosedCasesList = (data) => ({
    type: GET_FINAL_CLOSED_CASES_LIST,
    payload:data
});
export const GetFinalClosedCasesListSuccess = (data) => ({
    type: GET_FINAL_CLOSED_CASES_LIST_SUCCESS,
    payload:data
});
export const GetPendingResponseCasesList = (data) => ({
    type: GET_PENDING_RESPONSE_CASES_LIST,
    payload:data
});
export const GetPendingResponseCasesListSuccess = (data) => ({
    type: GET_PENDING_RESPONSE_CASES_LIST_SUCCESS,
    payload:data
});
export const GetOngoingCasesList = (data) => ({
    type: GET_ONGOING_CASES_LIST,
    payload:data
});
export const GetOngoingCasesListSuccess = (data) => ({
    type: GET_ONGOING_CASES_LIST_SUCCESS,
    payload:data
});
export const GetAuditDetails= (data) => ({
    type: GET_AUDIT_DETAILS,
    payload:data
});
export const GetAuditDetailsSuccess = (data) => ({
    type: GET_AUDIT_DETAILS_SUCCESS,
    payload:data
});
export const MapAuditError= (data) => ({
    type: MAP_AUDIT_ERRORS,
    payload:data
});
export const UpdateAuditErrorComments= (data) => ({
    type: UPDATE_AUDIT_ERROR_COMMENTS,
    payload:data
});
export const GetAuditErrorList = (data) => ({
    type: GET_AUDIT_ERRORS,
    payload:data
});
export const GetAuditErrorListSuccess = (data) => ({
    type: GET_AUDIT_ERRORS_SUCCESS,
    payload:data
});

export const GetAuditChangeLog = (data) => ({
    type: GET_AUDIT_CHANGE_LOG,
    payload:data
});
export const GetAuditChangeLogSuccess = (data) => ({
    type: GET_AUDIT_CHANGE_LOG_SUCCESS,
    payload:data
});
export const PerformAudit = (data) => ({
    type: PERFORM_AUDIT,
    payload:data
});
export const GetAuditIdSuccess = (data) => ({
    type: GET_AUDIT_ID_SUCCESS,
    payload:data
});
export const UpdateCaseStatus = (data) => ({
    type: UPDATE_CASE_STATUS,
    payload:data
});
export const UpdateCaseStatusSuccess = (data) => ({
    type: UPDATE_CASE_STATUS_SUCCESS,
    payload:data
});
