import React, { useEffect, useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getAuditDetails } from "../../../../application/selector/indexSelector";
import useAuth from "../../../../hooks/useAuth";

export default function AuditorRemark() {
    const {auth} =useAuth();
    const AuditDetailData= useSelector(getAuditDetails)
    const [auditComment, setAuditComment]= useState('')
    useEffect(()=>{
        setAuditComment(AuditDetailData?.overAllComment)
    },[AuditDetailData])
const handleSave=(e)=>{
    e.preventDefault();
    const Data={
        "id": AuditDetailData?.id,
        "formId": AuditDetailData.formId,
        "projectId": AuditDetailData.projectid,
        "caseId": AuditDetailData.caseId,
        "agentId": AuditDetailData.agentId,
        "supervisorId": AuditDetailData.supervisorId,
        "finalScore": AuditDetailData.finalScore,
        "auditScore": AuditDetailData.auditScore,
        "ataScore": AuditDetailData.ataScore,
        "rebuttalScore": AuditDetailData.rebuttalScore,
        "overAllRebuttal": AuditDetailData.overAllRebuttal,
        "overAllAuditorComment": auditComment,
        "status": AuditDetailData.status,
        "auditedBy": auth.id,
        "ataBy": auth.id
      }
      
}
    return (
        <React.Fragment>
            <Card.Header className="justify-content-between align-content-center ht-60">
                <h4 className="main-title fs-14 mb-0">Auditor Remark</h4>
                <Button onClick={handleSave}>Save</Button>
            </Card.Header>
            <Card.Body>
                <Form.Control as="textarea"  value={auditComment} name="auditComment" onChange={(e)=>setAuditComment(e.target.value)} rows={2} placeholder="Write your message..." />
            </Card.Body>
        </React.Fragment>
    )
}