import { GET_AUDITFORM_LIST_BY_PROJID_SUCCESS, GET_AUDITFORM_LIST_SUCCESS, GET_PROJECT_AUDIT_FORM_LIST_BYID_SUCCESS, GET_PROJECT_LIST_SUCCESS, MAPPED_AUDITFORM_COLUMN_LIST_SUCCESS, MAPPED_AUDITFORM_ERROR_LIST_SUCCESS, MAPPED_PROJECT_AUDIT_FORM_LIST_SUCCESS, MAPPED_PROJECT_AUDITOR_LIST_SUCCESS, MAPPED_PROJECT_COLUMN_LIST_SUCCESS, PROJECT_LIST_BY_AUDITFORM_ID_SUCCESS } from "../action/projectAction";

const initialState = {
    ProjectList: undefined,
    AuditFormList: undefined,
    ProjectAuditFormListById: undefined,
    MapProjectAuditFormList: undefined,
    MapProjectColumnList: undefined,
    MapAuditFormErrorsList: undefined,
    MapAuditFormColumnList: undefined,
    MapProjectAuditorList: undefined,
    MapProjectList: undefined,
    AuditFormListByProjId: undefined,
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROJECT_LIST_SUCCESS:
            return { ...state, ProjectList: action.payload.data, error: null };
        case GET_AUDITFORM_LIST_SUCCESS:
            return { ...state, AuditFormList: action.payload.data, error: null };
        case GET_AUDITFORM_LIST_BY_PROJID_SUCCESS:
            return { ...state, AuditFormListByProjId: action.payload.data, error: null };
        case GET_PROJECT_AUDIT_FORM_LIST_BYID_SUCCESS:
            return { ...state, ProjectAuditFormListById: action.payload.data, error: null };
        case MAPPED_PROJECT_AUDITOR_LIST_SUCCESS:
        return { ...state, MapProjectAuditorList: action.payload.data, error: null };
        case MAPPED_PROJECT_AUDIT_FORM_LIST_SUCCESS:
            return { ...state, MapProjectAuditFormList: action.payload.data, error: null };
        case MAPPED_PROJECT_COLUMN_LIST_SUCCESS:
            return { ...state, MapProjectColumnList: action.payload.data, error: null };
        case MAPPED_AUDITFORM_COLUMN_LIST_SUCCESS:
            return { ...state, MapAuditFormColumnList: action.payload.data, error: null };
        case MAPPED_AUDITFORM_ERROR_LIST_SUCCESS:
            return { ...state, MapAuditFormErrorsList: action.payload.data, error: null };
        case PROJECT_LIST_BY_AUDITFORM_ID_SUCCESS:
            return { ...state, MapProjectList: action.payload.data, error: null };
        default:
            return state;
    }
}

export default reducer;