import React from "react";
import { Button, Image } from "react-bootstrap";
import excelIcon from "../../../../assets/img/icons/excel.png"
import pdfIcon from "../../../../assets/img/icons/pdf.png"
import screenshortIcon from "../../../../assets/img/icons/screenshort.png";

export default function UploadedDocument() {
    return (
        <React.Fragment>
            <div className="mb-4">
                <h4 className="fs-16 fw-bold mb-2">Uploaded Documents</h4>
            </div>

            <div>
                <div className="d-flex align-items-center justify-content-between gap-2 mb-3">
                    <div className="d-flex gap-2">
                        <div>
                            <Image src={excelIcon} alt="icon" />
                        </div>
                        <div>
                            <h4 className="fs-14">Sample Excel File</h4>
                            <h6 className="fs-12 text-black-50">By: Aamir Sayyed</h6>
                        </div>
                    </div>

                    <div>
                        <Button variant="outline-dark" className="btn-icon"><i className="ri-download-2-line fs-18"></i></Button>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between gap-2 mb-3">
                    <div className="d-flex gap-2">
                        <div>
                            <Image src={screenshortIcon} alt="icon" />
                        </div>
                        <div>
                            <h4 className="fs-14">Schreenshot AMD</h4>
                            <h6 className="fs-12 text-black-50">By: Aamir Sayyed</h6>
                        </div>
                    </div>
                    <div>
                        <Button variant="outline-dark" className="btn-icon"><i className="ri-download-2-line fs-18"></i></Button>
                    </div>
                </div>
                <div className="d-flex align-items-center justify-content-between gap-2 mb-3">
                    <div className="d-flex gap-2">
                        <div>
                            <Image src={pdfIcon} alt="icon" />
                        </div>
                        <div>
                            <h4 className="fs-14">PDF File</h4>
                            <h6 className="fs-12 text-black-50">By: Aamir Sayyed</h6>
                        </div>
                    </div>
                    <div>
                        <Button variant="outline-dark" className="btn-icon"><i className="ri-download-2-line fs-18"></i></Button>
                    </div>
                </div>
            </div>

            <div className="mb-3 text-center">
                <Button variant="outline-primary">Upload Document</Button>
            </div>
        </React.Fragment>
    )
}