import React from "react";
import { useSelector } from "react-redux";
import { getAuditDetails } from "../../../../application/selector/indexSelector";
import { Col, Row } from "react-bootstrap";

export default function CaseData() {
    const AuditDetailData = useSelector(getAuditDetails)
    return (
        <React.Fragment>
            <div className="mb-3">
                <h4 className="fs-16 fw-bold mb-2">Case Data</h4>
            </div>
            <div>
                {AuditDetailData?.displayColumns?.map((data, index) => (
                    <Row className="align-items-center justify-content-between mb-2 border-bottom flex-wrap" key={index}>
                        <Col xl={6} md={12}>
                            <label className="card-title fs-sm fw-semibold text-black-50">{data.colName}</label>
                        </Col>
                        <Col xl={6} md={12}>
                            <h2 className="performance-value fs-sm fw-semibold mb-0">{data.colValue}</h2>
                        </Col>
                    </Row>
                ))}
            </div>
        </React.Fragment>
    )
}