import React, { useState } from 'react'
import { Nav, Tab } from 'react-bootstrap';
import Header from '../../../layouts/Header';
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from 'react-router-dom';
import SupervisorPendingAudits from './Pages/Pending/pending-audit';
import SupervisorCloseAudits from './Pages/Close/close-audit';

export default function SupervisorAudits() {
    const [isSidebarShow, setSidebarShow] = useState(false);

    return (
        <React.Fragment>
            <Header />
            <Tab.Container id="left-tabs-example" defaultActiveKey="pending_tab">
                <div className={"main main-file-manager" + (isSidebarShow ? " show" : "")}>
                    <PerfectScrollbar className="file-sidebar page-in-tabs">
                        <Nav variant="pills">
                            <Nav.Item><Nav.Link eventKey="pending_tab"><i className="ri-group-line"></i> Team Audits</Nav.Link></Nav.Item>
                            {/* <Nav.Item><Nav.Link eventKey="close_tab"><i className="ri-error-warning-line"></i> Close</Nav.Link></Nav.Item> */}
                        </Nav>
                    </PerfectScrollbar>

                    <PerfectScrollbar className="file-content p-3 p-lg-4">
                        <Link href="" className="menu-file-manager" onClick={() => setSidebarShow(!isSidebarShow)}>
                            <i className="ri-arrow-left-line"></i>
                        </Link>
                        <Tab.Content>
                            {/* PENDING AUDIT TAB */}
                            <Tab.Pane eventKey="pending_tab"><SupervisorPendingAudits /></Tab.Pane>

                            {/* CLOSE AUDIT TAB */}
                            {/* <Tab.Pane eventKey="close_tab"><SupervisorCloseAudits /></Tab.Pane> */}
                        </Tab.Content>
                    </PerfectScrollbar>
                </div>
            </Tab.Container>
        </React.Fragment>
    )
};
