import React from "react";
import { Offcanvas, Form, Button } from "react-bootstrap";

export default function RebuttalModal(props) {
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Rebuttal</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                <div>
                    <h5 className="fs-14 mb-1">Missing Data</h5>
                    <h6 className="fs-12 mb-2">Data Integrity Errors</h6>
                    <Form.Control as="textarea" rows={3} placeholder="Comment..." />
                </div>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button type="button" variant="primary" className="fs-14 me-2 d-flex align-items-center">
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Submit</span>
                    </Button>
                </div>
            </Offcanvas>

        </React.Fragment>
    )
}