import React from "react";
import { Offcanvas, Form } from "react-bootstrap";
import { format } from "date-fns";

export default function DataSourceView(props) {
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Data Source View</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <div className="product-wrapper p-2 mb-3">
                        <Form.Label className="fs-12 mb-0">DataSource</Form.Label>
                        <h6 className="fs-14 fw-semibold text-dark">{props?.sourceData?.datasourceName}</h6>
                    </div>

                    <div className="product-wrapper p-2 mb-3">
                        <Form.Label className="fs-12 mb-0">Inventory Total Count</Form.Label>
                        <h6 className="fs-14 fw-semibold text-dark">{props?.sourceData?.inventory_count}</h6>
                    </div>

                    <div className="product-wrapper p-2">
                        <Form.Label className="fs-12 mb-0">Last Uploaded</Form.Label>
                        <h6 className="fs-14 fw-semibold text-dark">{  props?.sourceData?.modifiedOn ?format(new Date(props?.sourceData?.modifiedOn), "dd MMM yyyy hh:mm a"): '-' }</h6>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    )
}