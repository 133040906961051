import React, { useState, useEffect, useRef } from "react";
import Header from "../../layouts/Header";
import DataTable from "react-data-table-component";
import { Badge, Button, Card, Form } from "react-bootstrap";
import PendingResponseData from "../../Json/PenddingResponse/pendding-response.json";
import UpdatePendingResponse from "./update-pending-response";
import { useNavigate } from "react-router-dom";
import { getPendingResponse, getLoading, getPendingResponseCasesList } from "../../application/selector/indexSelector";
import TableShimmerLoader from "../_common/ShimmerTable";
import {  useDispatch, useSelector } from "react-redux";
import useAuth from "../../hooks/useAuth";
import { GetPendingResponseCasesList } from "../../application/action/auditAction";
import LoadingBar from 'react-top-loading-bar';

export default function PendingResponse() {
    const dispatch = useDispatch();
    const {auth} =useAuth()
    const PendingResponseCasesList = useSelector(getPendingResponseCasesList)
    useEffect(()=>{
        dispatch(GetPendingResponseCasesList(auth.id))
    },[])
    const { loading, value } = useSelector(getLoading);
    const [showUpdatePendingResponse, setShowUpdatePendingResponse] = useState(false);
    const handleUpdatePendingResponse = (state) => {
        setShowUpdatePendingResponse(state);
    };  

    const columns = [

        {
            name: "Audit ID",
            selector: (row) => (
                <h6 className="fw-bold">{row.id}</h6>
            ),
            sortable: true,
        },
        {
            name: "Case ID",
            selector: (row) => row.caseId,
            sortable: true,
        },
        {
            name: "Audit Score",
            selector: (row) => row.finalScore,
            sortable: true,
        },

        {
            name: "Project Name",
            selector: (row) => row.projectName,
            sortable: true,
        },

        {
            name: "Response From",
            selector: (row) => row.response_from,
            sortable: true,
        },
        {
            name: "Comment",
            selector: (row) => (
                <h6 className="text-wrap pt-1 pb-1 fs-12">{row.overAllComment}</h6>
            ),
            sortable: true,
        },
        {
            name: "Audit Date",
            selector: (row) => row.updatedOn,
            sortable: true,
        },
        {
            name: "Audit Status",
            selector: (row) => (
                <Badge bg="warning"  pill>Pending to respond</Badge>
            )
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex">
                    <Button variant="primary" className="fs-12" onClick={()=>goStartAudit(row.id)}>Respond</Button>
                </div>
            ),
        }
    ];

    const navigate = useNavigate();

    const goStartAudit = (id) => {
        navigate(`/audit/detailspage/${id}`);
    };

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredPendingResponse = PendingResponseCasesList?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

     // PROGRESS TOP LOADER
     const loadingBarRef = useRef(null);
     useEffect(() => {
         if (loadingBarRef.current) {
             // Start the loading bar
             loadingBarRef.current.continuousStart();
     
             // Simulate a load completion or manage dynamically
             const timer = setTimeout(() => {
                 loadingBarRef.current.complete();
             }, 3000); // Adjust timing as needed
     
             // Cleanup timer
             return () => clearTimeout(timer);
         }
       }, []);
 

    return (
        <React.Fragment>
            <Header />
            <UpdatePendingResponse show={showUpdatePendingResponse} closeFunction={handleUpdatePendingResponse} />
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0">Pending Response</h4>
                    </div>
                </div>

                <Card className="card-one">
                    <Card.Header className="align-items-center justify-content-between">
                        <h4 className="main-title fs-14 mb-0">List of Pending Response</h4>
                        <div className="custom-dropdown-wrapper">
                            <div className="custom-drop-down z-index-2 wt-300">
                                <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                                <Form.Control
                                    type="text"
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {filteredPendingResponse && filteredPendingResponse !== undefined && (
                            <DataTable
                                columns={columns}
                                data={filteredPendingResponse}
                                fixedHeader
                                search={true}
                                highlightOnHover
                                pagination
                            ></DataTable>
                        )}
                        {!loading && filteredPendingResponse === undefined && (
                            <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                        )}
                        {loading &&
                            value === "PendingResponseList" && filteredPendingResponse === undefined && (
                                <TableShimmerLoader colNames={columns?.map((column) => column.name)} colsCount={1} />
                            )}
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    )
};