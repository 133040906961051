import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from './layouts/Main';
import NotFound from "./pages/NotFound";

import publicRoutes from "./routes/PublicRoutes";
import protectedRoutes from "./routes/ProtectedRoutes";

// import css
import "./assets/css/remixicon.css";

// import scss
import "./scss/style.scss";
import { AuthContextProvider } from "./infrastructure/core/helpers/AuthContext";
import { Provider } from "react-redux";
import { configureStore } from './application/store';
import services from './infrastructure/services/logger/index';
import RequireAuth from "./context/RequireAuth";


const ROLES = {
  Supervisor: "Supervisor",
  Auditor: "Auditor",
  User: "User",
  Admin: "Admin",
  Employee: "Employee",
};
// set skin on load
window.addEventListener("load", function () {
  let skinMode = localStorage.getItem("skin-mode");
  let HTMLTag = document.querySelector("html");

  if (skinMode) {
    HTMLTag.setAttribute("data-skin", skinMode);
  }
});

export default function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
      {/* <AuthContextProvider> */}
      <Provider store={configureStore(services)}>
      <Routes>
          {/* <Route path="/" element={<Main />}>
            {protectedRoutes.map((route, index) => {
              return (
                <Route
                  path={route.path}
                  element={route.element}
                  key={index}
                />
              )
            })}
          </Route>
          {publicRoutes.map((route, index) => {
            return (
              <Route
                path={route.path}
                element={route.element}
                key={index}
              />
            )
          })}
          <Route path="*" element={<NotFound />} /> */}
           <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Auditor, ROLES.Supervisor]} />}>
                {protectedRoutes.map((route, index) => {
                  return (
                    <Route
                      path={route.path}
                      element={route.element}
                      key={index}
                    />
                  );
                })}
              </Route>
            <Route>
              {publicRoutes.map((route, index) => {
                return (
                  <Route
                    path={route.path}
                    element={route.element}
                    key={index}
                  />
                );
              })}
            </Route>
            {/* catch all */}
            <Route path="*" element={<NotFound />} />
        </Routes>
      </Provider>
      {/* </AuthContextProvider> */}
      
      </BrowserRouter>
    </React.Fragment>
    
  );
}