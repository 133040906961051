import { Navigate } from "react-router-dom";
import * as Loading from "../action/loaderAction";
import { GET_AUDIT_CHANGE_LOG, GET_AUDIT_DETAILS, GET_CLOSED_CASES_LIST, GET_FINAL_CLOSED_CASES_LIST, GET_ONGOING_CASES_LIST, GET_OPENCASES_LIST, GET_PENDING_CASES_LIST, GET_PENDING_RESPONSE_CASES_LIST, GetAuditChangeLogSuccess, GetAuditDetailsSuccess, GetAuditErrorListSuccess, GetAuditIdSuccess, GetClosedCasesListSuccess, GetFinalClosedCasesListSuccess, GetOngoingCasesListSuccess, GetOpenCasesListSuccess, GetPendingCasesListSuccess, GetPendingResponseCasesListSuccess, MAP_AUDIT_ERRORS, PERFORM_AUDIT, UPDATE_AUDIT_ERROR_COMMENTS, UPDATE_CASE_STATUS, UpdateCaseStatusSuccess } from "../action/auditAction";


const OpenCasesList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_OPENCASES_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "openCasesList" }));
        const openCasesList = await api.auditAPI.OpenCasesList(action.payload);
        
        dispatch(GetOpenCasesListSuccess(openCasesList));
        dispatch(Loading.setLoading({ loading: false, value: "openCasesList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

  const GetPendingCasesList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_PENDING_CASES_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "pendingCasesList" }));
        const pendingCasesList = await api.auditAPI.GetPendingCaseList(action.payload);
        dispatch(GetPendingCasesListSuccess(pendingCasesList));
        dispatch(Loading.setLoading({ loading: false, value: "pendingCasesList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const GetClosedCasesList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_CLOSED_CASES_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "closedCasesList" }));
        const closedCasesList = await api.auditAPI.GetClosedCasesList(action.payload);
        
        dispatch(GetClosedCasesListSuccess(closedCasesList));
        dispatch(Loading.setLoading({ loading: false, value: "closedCasesList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const GetFinalClosedCasesList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_FINAL_CLOSED_CASES_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "closedCasesList" }));
        const closedCasesList = await api.auditAPI.GetFinalClosedCasesList(action.payload);
        dispatch(GetFinalClosedCasesListSuccess(closedCasesList));
        dispatch(Loading.setLoading({ loading: false, value: "closedCasesList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

  const GetPendingResponseCasesList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_PENDING_RESPONSE_CASES_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "pendingResponseCasesList" }));
        const pendingResponseCasesList = await api.auditAPI.GetPendingResponseCasesList(action.payload);
        
        dispatch(GetPendingResponseCasesListSuccess(pendingResponseCasesList));
        dispatch(Loading.setLoading({ loading: false, value: "closedCasesList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

  const GetOngoingCasesList =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_ONGOING_CASES_LIST) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "ongoingCasesList" }));
        const ongoingCasesList = await api.auditAPI.GetOngoingCasesList(action.payload);
        dispatch(GetOngoingCasesListSuccess(ongoingCasesList));
        dispatch(Loading.setLoading({ loading: false, value: "ongoingCasesList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const GetChangeLog =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_AUDIT_CHANGE_LOG) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "changeLog" }));
        const changeLog = await api.auditAPI.GetChangeLog(action.payload);
        dispatch(GetAuditChangeLogSuccess(changeLog));
        dispatch(Loading.setLoading({ loading: false, value: "changeLog" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const GetAuditDetails =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === GET_AUDIT_DETAILS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "AuditDetails" }));
        const AuditDetails = await api.auditAPI.GetAuditDetails(action.payload);
        dispatch(GetAuditDetailsSuccess(AuditDetails));
        dispatch(GetAuditErrorListSuccess(AuditDetails.data.mappedErrorsList));
        dispatch(Loading.setLoading({ loading: false, value: "AuditDetails" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };

  const MapAuditError =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === MAP_AUDIT_ERRORS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "auditErrorsList" }));
        const auditErrorsList = await api.auditAPI.MapAuditError(action.payload);
        dispatch(GetAuditErrorListSuccess(auditErrorsList.data));
        dispatch(Loading.setLoading({ loading: false, value: "auditErrorsList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const UpdateAuditErrorComments =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_AUDIT_ERROR_COMMENTS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "auditErrorsList" }));
        const auditErrorsList = await api.auditAPI.UpdateAuditErrorComments(action.payload);
        dispatch(GetAuditErrorListSuccess(auditErrorsList.data));
        dispatch(Loading.setLoading({ loading: false, value: "auditErrorsList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const PerformAudit =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === PERFORM_AUDIT) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "auditErrorsList" }));
        const auditId = await api.auditAPI.PerformAudit(action.payload);
        dispatch(GetAuditIdSuccess(auditId));
        dispatch(Loading.setLoading({ loading: false, value: "auditErrorsList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  const UpdateCaseStatus =
  ({ api }) =>
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type === UPDATE_CASE_STATUS) {
      try {
        dispatch(Loading.setLoading({ loading: true, value: "casesList" }));
        const casesList = await api.auditAPI.UpdateCaseStatus(action.payload);
        dispatch(GetPendingCasesListSuccess(casesList));
        dispatch(Loading.setLoading({ loading: false, value: "casesList" }));
      } catch (error) {
        console.log(error)
        if (error.response.data === "Invalid access token or refresh token") {
          localStorage.removeItem("Token");
          <Navigate to="/account/login" />;
        }
      }
    }
    next(action);
  };
  export default [
    OpenCasesList, GetClosedCasesList, GetPendingResponseCasesList, GetOngoingCasesList, GetPendingCasesList, GetAuditDetails, MapAuditError,UpdateAuditErrorComments,
     GetChangeLog, PerformAudit, UpdateCaseStatus, GetFinalClosedCasesList
  ]