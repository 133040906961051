import React, { useState } from "react";
import Header from "../../../layouts/Header";
import "./audit-details.scss";
import { Link } from "react-router-dom";
import { Card, Row, Col, Badge, Form, Button, Tabs, Tab, Image } from "react-bootstrap";
import CaseData from "./pages/case-data";
import { Doughnut } from "react-chartjs-2";
import AddErrorsModal from "./add-errors-modal";
import rebuttalIcon from "../../../assets/img/icons/audit/rebuttal-icon.png";
import excelIcon from "../../../assets/img/icons/excel.png"
import pdfIcon from "../../../assets/img/icons/pdf.png"
import screenshortIcon from "../../../assets/img/icons/screenshort.png";
import fatalErrorIcon from "../../../assets/img/icons/audit/fatal-error.png";
import nomFatalErrorIcon from "../../../assets/img/icons/audit/non-fatal-error.png";
import RebuttalModal from "./pages/rebuttal-comment";
import ChangeLog from "./pages/change-log";
import UploadedDocument from "./pages/uploaded-documents";
import AuditorRemark from "./pages/audtitor-remark";
import SupervisorRemark from "./pages/supervisor-remark";

export default function AuditDetails1() {
    const [activeTab, setActiveTab] = useState('case_data_tab');
    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };

    const [showAddError, setShowAddError] = useState(false);
    const handleAddError = (state) => {
        setShowAddError(state);
    }

    const data = {
        datasets: [
            {
                data: [90, 10], // 90% score and 10% empty to complete the semi-circle
                backgroundColor: (context) => {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;

                    // Check if chartArea is undefined; if so, return solid colors
                    if (!chartArea) return ['#098911', '#098911']; // Default color if gradient can't be applied

                    // Create the gradient
                    const gradient = ctx.createLinearGradient(
                        chartArea.left,
                        0,
                        chartArea.right,
                        0
                    );

                    // Define gradient color stops from red to green
                    gradient.addColorStop(0, '#098911'); // Red at the start
                    gradient.addColorStop(1, '#098911'); // Green at the end

                    return [gradient, '#E85353']; // Gradient for the score, gray for the remaining part
                },
                borderWidth: 0,
                cutout: '80%',
                rotation: -90,
                circumference: 180,
            },
        ],
    };

    const options = {
        rotation: -90, // Start from the top center
        circumference: 180, // Show only half circle
        plugins: {
            tooltip: { enabled: false },
            legend: { display: false },
        },
    };

    const [showRebuttalModal, setShowRebuttalModal] = useState(false);
    const handleRebuttalModal = (state) => {
        setShowRebuttalModal(state);
    }

    return (
        <React.Fragment>
            <Header />
            <AddErrorsModal show={showAddError} closeFunction={handleAddError} />
            <RebuttalModal show={showRebuttalModal} closeFunction={handleRebuttalModal} />
            <div className="main main-app manage-item-page audit-ata-page p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <ol className="breadcrumb fs-sm mb-1">
                            <li className="breadcrumb-item">
                                <Link to="/QaAudit/manage">QA Audit</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Audit #124578</li>
                        </ol>
                        <h4 className="main-title mb-0">Audit Details</h4>
                    </div>

                    <div className="gap-2 d-flex">
                        <Button type="button" variant="success" className="text-uppercase fw-semibold">Accept Audit</Button>
                        <Button type="button" variant="danger" className="text-uppercase fw-semibold">Reject & Rebuttal</Button>
                        <Button type="button" variant="primary" className="text-uppercase fw-semibold">Perform ATA</Button>
                    </div>
                </div>

                {/* AUDIT DETAILS INFO */}
                <Card className="card-one mb-3 h-auto">
                    <Card.Body>
                        <Row className="g-3 align-items-center">
                            <Col lg={6} md={6} sm={12}>
                                <div>
                                    <h2 className="mb-2"># <b>414575</b> <Badge className="fs-14" bg="warning" pill>Open</Badge></h2>
                                    <h3 className="fs-14">Project Name <i className="ri-arrow-right-s-line align-middle"></i> <span>Audit Form Name</span></h3>
                                </div>
                            </Col>
                            <Col lg={6} md={6} sm={12}>
                                <div className="d-flex flex-wrap lg-flex-space-between gap-5">
                                    <div>
                                        <h6 className="fs-14 fw-bold mb-1">Atik Shaikh</h6>
                                        <Form.Label className="mb-0">Agent Name</Form.Label>
                                    </div>
                                    <div>
                                        <h6 className="fs-14 fw-bold mb-1">Aamir Sayyed</h6>
                                        <Form.Label className="mb-0">Supervisor Name</Form.Label>
                                    </div>
                                    <div>
                                        <h6 className="fs-14 fw-bold mb-1">24587</h6>
                                        <Form.Label className="mb-0">LMIS Case ID</Form.Label>
                                    </div>
                                    <div>
                                        <h6 className="fs-14 fw-bold mb-1">12/09/2024</h6>
                                        <Form.Label className="mb-0">Action Date</Form.Label>
                                    </div>
                                    <div>
                                        <h6 className=" mb-1"><Badge variant="primary" className="fw-semibold" pill>YES</Badge></h6>
                                        <Form.Label className="mb-0">Rebuttal</Form.Label>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Row className="g-3">
                    {/* BOTH AUDIT SCORE */}
                    <Col md={4}>
                        <Card className="card-one audit-score-gauge-card">
                            <Card.Header className="ht-60">
                                <h4 className="main-title fs-14 mb-0"><span className="fw-semibold">Audit Score</span> (Aamir Sayyed)</h4>
                            </Card.Header>
                            <Card.Body>
                                <Row className="g-3 align-items-center">
                                    <Col md={8} className="mt-0">
                                        <div className="doughnut-chart-view" style={{ position: 'relative', width: '100%' }}>
                                            <Doughnut data={data} options={options} />
                                            <div className="doughnut-chart-value">
                                                <div>Overall Score</div>
                                                <div>90%</div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="d-flex justify-content-between mb-3 align-items-center">
                                            <div className="d-flex align-content-center gap-2">
                                                <span className="wt-20 ht-20 bg-danger d-block"></span>
                                                <span>Fatal Error</span>
                                            </div>
                                            <div><h6 className="fw-bold fs-14 text-dark">0</h6></div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-content-center gap-2">
                                                <span className="wt-20 ht-20 bg-warning d-block"></span>
                                                <span>Non-Fatal Error</span>
                                            </div>
                                            <div><h6 className="fw-bold fs-14 text-dark">1</h6></div>
                                        </div>
                                    </Col>
                                </Row>

                                {[
                                    {
                                        "name": "Data Integrity Error",
                                        "low": 25,
                                        "high": 75
                                    },
                                    {
                                        "name": "Authorization & Security Errors",
                                        "low": 30,
                                        "high": 70
                                    },
                                    {
                                        "name": "Identification & Matching Errors",
                                        "low": 0,
                                        "high": 100
                                    },
                                    {
                                        "name": "Operational & Processing Errors",
                                        "low": 100,
                                        "high": 0
                                    }].map((item, index) => (
                                        <Row className="mb-2 align-items-center" key={index}>
                                            <Col md={7}>
                                                <h3 className={`fs-14 text-dark fatal-error ${item.name === "Overall" ? "fw-bold" : ""}`}>{item.name}</h3>
                                            </Col>
                                            <Col md={5}>
                                                <div className="overall-score-progress">
                                                    <div className="progress" style={{ maxWidth: "100%" }}>
                                                        <div className="progress-bar bg-danger" style={{ width: `${item.low}%` }}>
                                                            <span>{item.low}%</span>
                                                        </div>
                                                        <div className="progress-bar bg-success" style={{ width: `${item.high}%` }}>
                                                            <span>{item.high}%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}


                                <hr />
                                {/* AUDITOR REMARK */}
                                <div>
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <h3 className="fs-14 fw-semibold">List of Errors</h3>
                                        <Button variant="primary" className="text-uppercase fs-12" onClick={() => handleAddError(true)}>Add Error</Button>
                                    </div>

                                    {/* PASS ERROR */}
                                    <Card className="card-one nonfatal-error-card h-auto mb-3">
                                        <Card.Header className="justify-content-between p-2 align-items-center" style={{ backgroundColor: "#fade8c" }}>
                                            <div className="d-flex align-items-center gap-2">
                                                <div>
                                                    <Image src={nomFatalErrorIcon} alt="non Fatal" />
                                                </div>
                                                <div>
                                                    <h4 className="fs-14 text-white">Unauthorized Access</h4>
                                                    <h6 className="fs-12 text-white">Data Integrity Error</h6>
                                                </div>
                                            </div>
                                            <div>
                                                <Badge pill className="pass-badge text-white">PASS</Badge>
                                            </div>
                                        </Card.Header>

                                        <Card.Body>
                                            <div>
                                                <div className="msg-item fs-14 mt-2">
                                                    This is a sample comment. This is a sample comment. This is a sample comment.
                                                </div>
                                            </div>
                                        </Card.Body>

                                        <div className="text-end p-2">
                                            <Button variant="default" onClick={() => handleRebuttalModal(true)}>
                                                <Image className="align-middle wt-18 me-1" src={rebuttalIcon} alt="icon" />
                                                <span className="align-middle fw-semibold">Rebuttal</span>
                                            </Button>
                                        </div>
                                    </Card>

                                    {/* FAIL ERROR */}
                                    <Card className="card-one fatal-error-card h-auto mb-3">
                                        <Card.Header className="justify-content-between p-2 align-items-center" style={{ backgroundColor: "#E85353" }}>
                                            <div className="d-flex align-items-center gap-2">
                                                <div>
                                                    <Image src={fatalErrorIcon} alt="Fatal" />
                                                </div>
                                                <div>
                                                    <h4 className="fs-14 text-white">Unauthorized Access</h4>
                                                    <h6 className="fs-12 text-white">Data Integrity Error</h6>
                                                </div>
                                            </div>
                                            <div>
                                                <Badge pill className="pass-badge text-white">FAIL</Badge>
                                            </div>
                                        </Card.Header>

                                        <Card.Body>
                                            <div>
                                                <div className="msg-item fs-14 mt-2">
                                                    This is a sample comment. This is a sample comment. This is a sample comment.
                                                </div>
                                                <div className="msg-item p-1 msg-bubble reverse fs-14 mt-2">
                                                    This is a sample comment. This is a sample comment. This is a sample comment.
                                                </div>
                                            </div>
                                        </Card.Body>

                                        <div className="text-end p-2">
                                            <Button variant="default" onClick={() => handleRebuttalModal(true)}>
                                                <Image className="align-middle wt-18 me-1" src={rebuttalIcon} alt="icon" />
                                                <span className="align-middle fw-semibold">Rebuttal</span>
                                            </Button>
                                        </div>
                                    </Card>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* FAIL AUDIT SCOREE */}
                    <Col md={4}>
                        <Card className="card-one audit-score-gauge-card">
                            <Card.Header className="ht-60">
                                <h4 className="main-title fs-14 mb-0"><span className="fw-semibold">Audit Score</span> (Aamir Sayyed)</h4>
                            </Card.Header>
                            <Card.Body>
                                <Row className="g-3 align-items-center">
                                    <Col md={8} className="mt-0">
                                        <div className="doughnut-chart" style={{ width: '250px', height: '250px', position: "relative" }}>
                                            <Doughnut data={data} options={options} />
                                            <div style={{
                                                position: 'absolute',
                                                top: '55%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                textAlign: 'center',
                                                fontSize: '18px',
                                                fontWeight: 'bold',
                                            }}>
                                                <div>Overall Score</div>
                                                <div>90%</div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={4}>
                                        <div className="d-flex justify-content-between mb-3 align-items-center">
                                            <div className="d-flex align-content-center gap-2">
                                                <span className="wt-20 ht-20 bg-danger d-block"></span>
                                                <span>Fatal Error</span>
                                            </div>
                                            <div><h6 className="fw-bold fs-14 text-dark">0</h6></div>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex align-content-center gap-2">
                                                <span className="wt-20 ht-20 bg-warning d-block"></span>
                                                <span>Non-Fatal Error</span>
                                            </div>
                                            <div><h6 className="fw-bold fs-14 text-dark">1</h6></div>
                                        </div>
                                    </Col>
                                </Row>

                                {[
                                    {
                                        "name": "Data Integrity Error",
                                        "low": 25,
                                        "high": 75
                                    },
                                    {
                                        "name": "Authorization & Security Errors",
                                        "low": 30,
                                        "high": 70
                                    },
                                    {
                                        "name": "Identification & Matching Errors",
                                        "low": 0,
                                        "high": 100
                                    },
                                    {
                                        "name": "Operational & Processing Errors",
                                        "low": 100,
                                        "high": 0
                                    }].map((item, index) => (
                                        <Row className="mb-2 align-items-center" key={index}>
                                            <Col md={7}>
                                                <h3 className={`fs-14 text-dark fatal-error ${item.name === "Overall" ? "fw-bold" : ""}`}>{item.name}</h3>
                                            </Col>
                                            <Col md={5}>
                                                <div className="overall-score-progress">
                                                    <div className="progress" style={{ maxWidth: "100%" }}>
                                                        <div className="progress-bar bg-danger" style={{ width: `${item.low}%` }}>
                                                            <span>{item.low}%</span>
                                                        </div>
                                                        <div className="progress-bar bg-success" style={{ width: `${item.high}%` }}>
                                                            <span>{item.high}%</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}


                                <hr />
                                {/* AUDITOR REMARK */}
                                <div>
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <h3 className="fs-14 fw-semibold">List of Errors</h3>
                                        <Button variant="primary" className="text-uppercase fs-12" onClick={() => handleAddError(true)}>Add Error</Button>
                                    </div>

                                    {/* FAIL ERROR */}
                                    <Card className="card-one fatal-error-card h-auto mb-3">
                                        <Card.Header className="justify-content-between p-2 align-items-center" style={{ backgroundColor: "#E85353" }}>
                                            <div className="d-flex align-items-center gap-2">
                                                <div>
                                                    <Image src={fatalErrorIcon} alt="Fatal" />
                                                </div>
                                                <div>
                                                    <h4 className="fs-14 text-white">Unauthorized Access</h4>
                                                    <h6 className="fs-12 text-white">Data Integrity Error</h6>
                                                </div>
                                            </div>
                                            <div>
                                                <Badge pill className="pass-badge text-white">FAIL</Badge>
                                            </div>
                                        </Card.Header>

                                        <Card.Body>
                                            <div>
                                                <div className="msg-item fs-14 mt-2">
                                                    This is a sample comment. This is a sample comment. This is a sample comment.
                                                </div>

                                                <div className="msg-item p-1 msg-bubble reverse fs-14 mt-2">
                                                    This is a sample comment. This is a sample comment. This is a sample comment.
                                                </div>
                                            </div>
                                        </Card.Body>

                                        <div className="text-end p-2">
                                            <Button variant="default" onClick={() => handleRebuttalModal(true)}>
                                                <Image className="align-middle wt-18 me-1" src={rebuttalIcon} alt="icon" />
                                                <span className="align-middle fw-semibold">Rebuttal</span>
                                            </Button>
                                        </div>
                                    </Card>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* UPLOADED DOCUMENTS AND CASE DATA CHANGE LOG TABS */}
                    <Col md={4}>
                        <Row className="g-3 mb-3">
                            <Col sm={12}>
                                <Card className="card-one">
                                    <AuditorRemark />
                                </Card>
                            </Col>
                            <Col sm={12}>
                                <Card className="card-one">
                                    <SupervisorRemark />
                                </Card>
                            </Col>
                        </Row>
                        {/* CASE DATA AND CHANGE LOG TABS */}
                        <Card className="card-one h-auto">
                            <Tabs id="custom-styled-tabs" activeKey={activeTab} onSelect={handleTabSelect} className="common-tabs">
                                <Tab eventKey="case_data_tab" title="Case Data" tabClassName="custom-tab-header fw-semibold">
                                    <Card.Body>
                                        <div className="custom-tab-body">
                                            <CaseData />
                                        </div>
                                    </Card.Body>
                                </Tab>

                                <Tab eventKey="uploaded_document_data_tab" title="Uploaded Documents" tabClassName="custom-tab-header fw-semibold">
                                    <Card.Body>
                                        <UploadedDocument />
                                    </Card.Body>
                                </Tab>

                                <Tab eventKey="upload_document_tab" title="Change Log" tabClassName="custom-tab-header fw-semibold">
                                    <div className="custom-tab-body">
                                        <Card.Body>
                                            <ChangeLog />
                                        </Card.Body>
                                    </div>
                                </Tab>
                            </Tabs>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
};