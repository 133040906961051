import React from "react";
import { useSelector } from "react-redux";
import { getAuditDetails } from "../../../../application/selector/indexSelector";

export default function CaseData() {
    const AuditDetailData= useSelector(getAuditDetails)

    return (
        <React.Fragment>
            <ul className="list-group list-group-flush">
                {AuditDetailData?.displayColumns?.map((data, index) => (
                    <li className="list-group-item d-flex justify-content-between" key={index}>
                        <b><span className="fs-14">{data.colName}</span></b>
                        <span className="fs-14">{data.colValue}</span>
                    </li>
                ))}
            </ul>
        </React.Fragment>
    );
}
