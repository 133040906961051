import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import CreateAuditForm from "./create-audit-form";

export default function AuditDetailsView(props) {
    const [showCreateAuditForm, setShowCreateAuditForm] = useState(false);
    const handleEditAudit =(state)=> {
        setShowCreateAuditForm(state);
    };
    return (
        <React.Fragment>
            <CreateAuditForm show={showCreateAuditForm} closeFunction={handleEditAudit} />
            <div className="d-flex align-items-center justify-content-between mb-3">
                <h3 className="fs-14 mb-0">Add error opportunities</h3>
            </div>
            <Table>
                <thead>
                    <tr>
                        <th>Audit Form Name</th>
                        <th>Description</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{props?.data?.auditFormName}</td>
                        <td>{props?.data?.auditFormDescription}</td>
                        <td>
                            <Button type="button" variant="primary" className="btn-icon" onClick={()=> handleEditAudit(true)}>
                                <i className="ri-pencil-line"></i>
                            </Button>
                        </td>

                    </tr>
                </tbody>
            </Table>
        </React.Fragment>
    )
}