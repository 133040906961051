import React, { useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import UpdateAuditForm from "./update-audit-form";
import { useSelector, useDispatch } from "react-redux";
import { getProjectList } from "../../application/selector/indexSelector";
import { AddAuditForm } from "../../application/action/projectAction";
import Environment from "../../infrastructure/core/Environment";
import useAuth from "../../hooks/useAuth";

export default function CreateAuditForm(props) {
    const { auth } = useAuth()
    const dispatch = useDispatch();
    const ProjectsList = useSelector(getProjectList)
    const initialState = {
        "projectId": Environment.defaultValue,
        "auditFormName": "",
        "auditFormDescription": "",
        "createdBy": "d8f2f783-4461-4dec-9142-d86c7104944a",
        "modifiedBy": "d8f2f783-4461-4dec-9142-d86c7104944a"
    }
    const [formData, setFormData] = useState(initialState);
    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const [showUpdateAuditForm, setShowUpdateAuditForm] = useState(false);
    const handleCreateAudit = (state) => {
        setShowUpdateAuditForm(state);
        props.closeFunction(false);
    };
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(AddAuditForm(formData))
        handleCreateAudit(false)
        setFormData(initialState)

    }
    return (
        <React.Fragment>
            <UpdateAuditForm show={showUpdateAuditForm} closeFunction={handleCreateAudit} />
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Create New Audit Form</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <Row>
                        <Col md={8}>
                            <div className="mb-4">
                                <Form.Label>Project Name</Form.Label>
                                <Form.Select className="wt-400" name="projectId" value={formData.projectId} onChange={handleInputChange}>
                                    <option>Choose Project Name</option>
                                    {ProjectsList?.map((pro) => (
                                        <option key={pro.id} value={pro.id}>
                                            {pro.projectName}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>

                        <Col md={8}>
                            <div className="mb-4">
                                <Form.Label>Audit Form Name</Form.Label>
                                <Form.Control type="text" placeholder="Enter Audit Form Name" name="auditFormName" value={formData.auditFormName} onChange={handleInputChange} />
                            </div>
                        </Col>


                        <Col md={12}>
                            <div className="mb-4">
                                <Form.Label>Description</Form.Label>
                                <Form.Control type="text" as="textarea" rows={4} placeholder="Enter Description" name="auditFormDescription" value={formData.auditFormDescription} onChange={handleInputChange} />
                            </div>
                        </Col>
                    </Row>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button type="submit" variant="primary" className="fs-14 me-2 d-flex align-items-center" onClick={handleSubmit}>
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Create Audit Form</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    );
}
