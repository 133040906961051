import { GET_AUDIT_CHANGE_LOG_SUCCESS, GET_AUDIT_DETAILS_SUCCESS, GET_AUDIT_ERRORS_SUCCESS, GET_AUDIT_ID_SUCCESS, GET_CLOSED_CASES_LIST_SUCCESS, GET_FINAL_CLOSED_CASES_LIST_SUCCESS, GET_ONGOING_CASES_LIST_SUCCESS, GET_OPENCASES_LIST_SUCCESS, GET_PENDING_CASES_LIST_SUCCESS, GET_PENDING_RESPONSE_CASES_LIST_SUCCESS, UPDATE_CASE_STATUS_SUCCESS } from "../action/auditAction";

const initialState = {
    OpenCasesList: undefined,
    ClosedCasesList: undefined,
    FinalClosedCasesList: undefined,
    PendingResponseCasesList: undefined,
    OngoingCasesList: undefined,
    PendingCasesList: undefined,
    AuditDetails: undefined,
    AuditErrors: undefined,
    ChangeLog: undefined,
    CaseId: undefined,
    error: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_OPENCASES_LIST_SUCCESS:
            return { ...state, OpenCasesList: action.payload.data, error: null };
        case GET_CLOSED_CASES_LIST_SUCCESS:
            return { ...state, ClosedCasesList: action.payload.data, error: null };
        case GET_FINAL_CLOSED_CASES_LIST_SUCCESS:
            return { ...state, FinalClosedCasesList: action.payload.data, error: null };
        case GET_PENDING_RESPONSE_CASES_LIST_SUCCESS:
            return { ...state, PendingResponseCasesList: action.payload.data, error: null };
        case GET_ONGOING_CASES_LIST_SUCCESS:
            return { ...state, OngoingCasesList: action.payload.data, error: null };
        case GET_PENDING_CASES_LIST_SUCCESS:
            return { ...state, PendingCasesList: action.payload.data, error: null };
        case GET_AUDIT_DETAILS_SUCCESS:
            return { ...state, AuditDetails: action.payload.data, error: null };
        case GET_AUDIT_ERRORS_SUCCESS:
            return { ...state, AuditErrors: action.payload, error: null };
        case GET_AUDIT_CHANGE_LOG_SUCCESS:
            return { ...state, ChangeLog: action.payload.data, error: null };
        case GET_AUDIT_ID_SUCCESS:
            return { ...state, AuditId: action.payload.data, error: null };
        case UPDATE_CASE_STATUS_SUCCESS:
            return { ...state, CaseId: action.payload.data, error: null };
        default:
            return state;
    }
}

export default reducer;