import React, { useEffect, useState } from "react";
import { Card, Row, Col, Form, Button, Badge } from "react-bootstrap";
import { useParams } from "react-router-dom";
import useAuth from "../../../../../hooks/useAuth";
import { useDispatch, useSelector } from "react-redux";
import { getAuditDetails, getAuditErrors, getMapAuditFormErrorsList } from "../../../../../application/selector/indexSelector";
import Environment from "../../../../../infrastructure/core/Environment";
import { PerformAudit, UpdateAuditErrorComments } from "../../../../../application/action/auditAction";

export default function AuditScore({ ErrorChanges, setAuditorComments, setSupervisorComments }) {
    const { auditId } = useParams();
    const { auth } = useAuth();
    const dispatch = useDispatch();
    const [errorMessages, setErrorMessages] = useState({});
    const AuditDetailData = useSelector(getAuditDetails)
    const [IsAccepted, setIsAccepted] = useState(true);
    const MappedErrorsRaw = useSelector(getAuditErrors) || [];
    const ErrorsList = useSelector(getMapAuditFormErrorsList) || [];
    const [formData, setFormData] = useState({
        overAllComment: '',
        overAllRebuttal: ''
    })
    
    

    // State for toggle switches and remarks
    const [toggleStates, setToggleStates] = useState({});
    const [remarks, setRemarks] = useState([]);

    // USEEFFECT
    useEffect(() => {
        setFormData({
            ...formData, overAllComment: AuditDetailData?.overAllComment,
            overAllRebuttal: AuditDetailData?.overAllRebuttal
        })
    }, [AuditDetailData])

    // USEEFFECT
    useEffect(() => {
        const initialToggleStates = {};
        const initialRemarks = [];
        ErrorsList.forEach((item) => {
            item.subErrorCategory.forEach((sub) => {
                sub.errorType.forEach((error) => {
                    const mapData = MappedErrorsRaw.errorsList?.find((mapped) => mapped.errorId === error.id);
                    const isMet = !MappedErrorsRaw.errorsList?.some((mapped) => mapped.errorId === error.id);

                    const key = `${item.errorCategoryId}_${error.id}`; // Use composite key
                    if (!initialToggleStates[key] && (mapData?.isRebutAccepted === '15d57d26-1bc8-431e-a88a-ebb634aba862' || mapData?.isRebutAccepted === Environment.defaultValue)) {
                        initialToggleStates[key] = false;
                    } else {
                        initialToggleStates[key] = true;
                    }
                    if (isMet && !initialRemarks.some((r) => r.errorId === error.id && mapData.addedBy !=='supervisor')) {
                        initialRemarks.push({
                            errorId: error.id,
                            remark: mapData?.auditComment || "",
                            supervisorRebuttal: mapData?.supComment || "",
                            mappingId: mapData?.id,
                            accept: mapData?.isRebutAccepted,
                            met: isMet,
                            errorCategoryId: item.errorCategoryId, // Store categoryId
                        });
                    }
                    else if (!isMet && mapData.isRebutAccepted === '15d57d26-1bc8-431e-a88a-ebb634aba861') {
                        initialRemarks.push({
                            errorId: error.id,
                            remark: mapData?.auditComment || "",
                            supervisorRebuttal: mapData?.supComment || "",
                            mappingId: mapData?.id,
                            met: true,
                            accept: mapData?.isRebutAccepted,
                            errorCategoryId: item.errorCategoryId, // Store categoryId
                        });
                    }
                    else {
                        initialRemarks.push({
                            errorId: error.id,
                            remark: mapData?.auditComment,
                            supervisorRebuttal: mapData?.supComment || "",
                            mappingId: mapData?.id,
                            met: isMet,
                            accept: mapData?.isRebutAccepted,
                            errorCategoryId: item.errorCategoryId, // Store categoryId
                        });
                    }
                });
            });
        });

        if (JSON.stringify(toggleStates) !== JSON.stringify(initialToggleStates)) {
            setToggleStates(initialToggleStates);
        }
        ErrorChanges(initialRemarks);
        if (JSON.stringify(remarks) !== JSON.stringify(initialRemarks)) {
            setRemarks(initialRemarks);
        }
    }, [ErrorsList, MappedErrorsRaw]);

    // HANDLE TOGGLE
    const handleToggle = (checked, errorCategoryId, errorId, supervisorRebuttal) => {
        const hasErrors = remarks.some((remark) => !remark.met && !remark.remark.trim());

        if (!checked && hasErrors) {
            return; // Block toggling
        }

        const key = `${errorCategoryId}_${errorId}`; // Composite key
        setToggleStates((prevStates) => ({
            ...prevStates,
            [key]: checked,
        }));

        setRemarks((prevRemarks) => {
            const updatedRemarks = [...prevRemarks];
            const remarkIndex = updatedRemarks.findIndex((remark) => remark.errorId === errorId);

            if (remarkIndex > -1) {
                updatedRemarks[remarkIndex] = {
                    ...updatedRemarks[remarkIndex],
                    met: checked,
                };
            } else {
                updatedRemarks.push({
                    errorId,
                    remark: "",
                    supervisorRebuttal: supervisorRebuttal,
                    mappingId: null,
                    met: checked,
                    errorCategoryId, // Include categoryId
                });
            }
            ErrorChanges(updatedRemarks);
            return updatedRemarks;
        });

        setErrorMessages((prev) => ({
            ...prev,
            [errorId]: "Remark is required.",
        }));
    };

    // HANDLE REMARK CHANGE
    const handleRemarkChange = (errorId, value) => {
        setRemarks((prevRemarks) => {
            const updatedRemarks = [...prevRemarks];
            const remarkIndex = updatedRemarks.findIndex((remark) => remark.errorId === errorId);

            if (remarkIndex > -1) {
                updatedRemarks[remarkIndex] = {
                    ...updatedRemarks[remarkIndex],
                    remark: value,
                }; // Update existing remark
                setErrorMessages((prev) => ({
                    ...prev,
                    [errorId]: null, // Clear the error message
                }));
            } else {
                updatedRemarks.push({
                    errorId,
                    remark: value,
                    mappingId: null,
                    met: false, // Default when creating a new remark
                }); // Add new remark if not found
            }

            ErrorChanges(updatedRemarks)
            return updatedRemarks;
        });
    };
    const handleAccept = (errorCategoryId, error, value) => {
        
        const data = remarks.find((remark) => remark.errorId === error.id)
        setRemarks((prevRebuttal) =>
            prevRebuttal.map((remark) =>
                remark.errorId === error.Id
                    ? { ...remark, accept: value }
                    : remark
            )
        ); const key = `${errorCategoryId}_${error.id}`; // Composite key
        setToggleStates((prevStates) => ({
            ...prevStates,
            [key]: true,
        }));

        setRemarks((prevRemarks) => {
            const updatedRemarks = [...prevRemarks];
            const remarkIndex = updatedRemarks.findIndex((remark) => remark.errorId === error.id);
            if (remarkIndex > -1) {
                // Update existing remark
                updatedRemarks[remarkIndex] = {
                    ...updatedRemarks[remarkIndex],
                    accept: value,
                    met: true
                };
            } else {
                // Add new remark if not found
                updatedRemarks.push({
                    errorId: error.id,
                    remark: "",
                    mappingId: null,
                    met: true,
                    accept: value, // Add accept property
                });
            }
            // Callback for changes
            ErrorChanges(updatedRemarks);

            return updatedRemarks;
        });
        const ErrorDetails = [
            {
                "id": data.mappingId,
                "errorId": error.id,
                "auditId": auditId,
                "auditorComment": data.remark,
                "supervisorRebuttal": data.supervisorRebuttal,
                "isRebutAccepted": value,
                "addedBy ":  data.addedBy,
                "updatedBy": auth.id
            }
        ]
        dispatch(UpdateAuditErrorComments(ErrorDetails))

    }
    const handleReject = (error, value) => {
        setRemarks((prevRebuttal) =>
            prevRebuttal.map((remark) =>
                remark.errorId === error.Id
                    ? { ...remark, accept: value }
                    : remark
            )
        );
        setRemarks((prevRemarks) => {
            const updatedRemarks = [...prevRemarks];
            const remarkIndex = updatedRemarks.findIndex((remark) => remark.errorId === error.id);
            if (remarkIndex > -1) {
                // Update existing remark
                updatedRemarks[remarkIndex] = {
                    ...updatedRemarks[remarkIndex],
                    accept: value,
                    met: false
                };
            } else {
                // Add new remark if not found
                updatedRemarks.push({
                    errorId: error.id,
                    remark: "",
                    mappingId: null,
                    met: false,
                    accept: value, // Add accept property
                });
            }
            
            // Callback for changes
            ErrorChanges(updatedRemarks);
            return updatedRemarks;
        });
        const data = remarks.find((remark) => remark.errorId === error.id)
        const ErrorDetails = [
            {
                "id": data.mappingId,
                "errorId": error.id,
                "auditId": auditId,
                "auditorComment": data.remark,
                "supervisorRebuttal": data.supervisorRebuttal,
                "isRebutAccepted": value,
                "addedBy ":  data.addedBy,
                "updatedBy": auth.id
            }
        ]
        dispatch(UpdateAuditErrorComments(ErrorDetails))
    }
    return (
        <React.Fragment>
            {ErrorsList.filter((sub) =>
                sub.subErrorCategory.some((data) => data.errorType?.length > 0)
            ).map((item, index) => (
                <Card key={index} className="card-one sub-error-card h-auto mb-3">
                    <Card.Header className="justify-content-between">
                        <div>
                            <h3 className="fs-16 fw-bold">{item.errorCategoryName}</h3>
                        </div>
                        <div>
                            <div className="overall-score-progress wt-200">
                                <div className="progress ht-20" style={{ maxWidth: "100%" }}>
                                    {(() => {
                                        const totalErrors = item.subErrorCategory.reduce((total, sub) => total + sub.errorType.length, 0);
                                        const metCount = item.subErrorCategory.reduce(
                                            (count, sub) => count + sub.errorType.filter((error) => toggleStates[`${item.errorCategoryId}_${error.id}`]).length,
                                            0
                                        );
                                        const metPercentage = totalErrors > 0 ? (metCount / totalErrors) * 100 : 0;
                                        const notMetPercentage = 100 - metPercentage;
                                        return (
                                            <>
                                                <div className="progress-bar bg-success" style={{ width: `${metPercentage}%` }}>
                                                    <span>{Math.round(metPercentage)}%</span>
                                                </div>
                                                <div className="progress-bar bg-danger" style={{ width: `${notMetPercentage}%` }}>
                                                    <span>{Math.round(notMetPercentage)}%</span>
                                                </div>
                                            </>
                                        );
                                    })()}
                                </div>
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {item.subErrorCategory
                            .filter((data) => data.errorType.length > 0) // Only include categories with error types
                            .map((data) =>
                                <div className="sub-error-view" key={data.error_type}>
                                    <div className="mb-3 d-flex justify-content-between align-items-center">
                                        <h3 className="fs-16 fw-semibold">{data.subErrorCatName}</h3>
                                        <h3 className="fs-16 fw-semibold wt-50">Score</h3>
                                    </div>
                                    {data.errorType.map((error) => (
                                        <Row className="mb-2 align-items-center g-3" key={error.id}>
                                            <Col xl={3} md={3} xs={12}>
                                                <div className="d-flex align-items-center gap-3">
                                                    <div className="error-status-toggle">
                                                        {remarks?.some((remark) => remark.supervisorRebuttal !== "") ? (
                                                            <Badge bg={`${toggleStates[`${item.errorCategoryId}_${error.id}`] ? "success" : "danger"}`} className="text-uppercase fs-12 fw-semibold" pill>{toggleStates[`${item.errorCategoryId}_${error.id}`] ? "Met" : "Not Met"}</Badge>
                                                        ) : (
                                                            <Form.Check
                                                                type="switch"
                                                                id={`custom-switch-${item.errorCategoryId}_${error.id}`}
                                                                checked={toggleStates[`${item.errorCategoryId}_${error.id}`] || false} // Use composite key
                                                                className="mb-0"
                                                                onChange={(e) =>
                                                                    handleToggle(e.target.checked, item.errorCategoryId, error.id) // Pass categoryId
                                                                }
                                                                label={
                                                                    <div className="toggle-wrapper">
                                                                        <span className={`toggle-text ${toggleStates[`${item.errorCategoryId}_${error.id}`] ? "on" : "off"}`}>
                                                                            {toggleStates[`${item.errorCategoryId}_${error.id}`] ? "Met" : "Not Met"}
                                                                        </span>
                                                                    </div>
                                                                }
                                                                custom
                                                            />)}

                                                    </div>
                                                    <h4 className="fs-14 wt-300">{error.errorName}</h4>
                                                </div>
                                            </Col>
                                            <Col xl={3} md={3} xs={12} className="custom-col">
                                                <div className="d-flex align-items-center gap-2 sub-error-list ">
                                                    <div className="wt-350">
                                                        {!toggleStates[`${item.errorCategoryId}_${error.id}`] && (
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Enter Remarks"
                                                                value={remarks.find((remark) => remark.errorId === error.id)?.remark || ""}
                                                                onChange={(e) => handleRemarkChange(error.id, e.target.value)}

                                                            />
                                                        )}
                                                        {!toggleStates[`${item.errorCategoryId}_${error.id}`] && errorMessages[error.id] && (
                                                            <span className="text-danger fs-12 position-absolute">{errorMessages[error.id]}</span>
                                                        )}
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col xl={3} md={3} xs={12} className="custom-col">
                                                <div className="d-flex align-items-center sub-error-list">
                                                    {(!toggleStates[`${item.errorCategoryId}_${error.id}`] && (remarks?.find((remark) => remark.errorId === error.id)?.supervisorRebuttal !== "")) && (
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter Remarks"
                                                            value={remarks.find((remark) => remark.errorId === error.id)?.supervisorRebuttal}
                                                            // onChange={(e) => handleRemarkChange(error.id, e.target.value)}
                                                            disabled
                                                        />
                                                    )}



                                                </div>
                                            </Col>
                                            <Col xl={2} md={3} xs={12} className="custom-col">
                                                <div className="d-flex align-items-center gap-3 sub-error-list">

                                                    {((remarks?.find((remark) => remark.errorId === error.id)?.supervisorRebuttal !== "") && remarks?.find((remark) => remark.errorId === error.id)?.accept === Environment.defaultValue) && (
                                                        <> <Button variant="success" className="text-uppercase" onClick={() => handleAccept(item.errorCategoryId, error, '15d57d26-1bc8-431e-a88a-ebb634aba861')}>ACCEPT</Button>
                                                            <Button variant="danger" className="text-uppercase" onClick={() => handleReject(error, '15d57d26-1bc8-431e-a88a-ebb634aba862')}>REJECT</Button></>
                                                    )}
                                                    {((remarks?.find((remark) => remark.errorId === error.id)?.supervisorRebuttal !== "") && remarks?.find((remark) => remark.errorId === error.id)?.accept !== Environment.defaultValue) && (
                                                        <>
                                                            <Button variant={`${remarks?.find((remark) => remark.errorId === error.id)?.accept === '15d57d26-1bc8-431e-a88a-ebb634aba862' ? 'danger' : 'success'}`} className="text-uppercase" >{remarks?.find((remark) => remark.errorId === error.id)?.accept === '15d57d26-1bc8-431e-a88a-ebb634aba862' ? 'REJECTED' : 'Accepted'}</Button></>
                                                    )}
                                                </div>
                                            </Col>
                                            <Col md={1} xs={12}>
                                                <Row className="g-3">
                                                    <div className="d-flex align-items-center  sub-error-list">
                                                        <div className="wt-50 text-center">
                                                            <h5
                                                                className="error-point"
                                                                style={{
                                                                    backgroundColor: !toggleStates[`${item.errorCategoryId}_${error.id}`]
                                                                        ? error.points === 1
                                                                            ? "#FFE599"
                                                                            : error.points === 5
                                                                                ? "#FBD38D"
                                                                                : error.points === 10
                                                                                    ? "#F6BB7D"
                                                                                    : error.points === 20
                                                                                        ? "#EC8D5E"
                                                                                        : error.points === 50
                                                                                            ? "#CC0000"
                                                                                            : "#cccccc"
                                                                        : "#cccccc",
                                                                }}
                                                            >
                                                                {error.points}
                                                            </h5>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                            )}
                    </Card.Body>
                </Card>
            ))}

            <Card className="card-one h-auto">
                <Card.Body>
                    <h3 className="fs-14 mb-3 fw-bold">Remark</h3>
                    <Row className="g-3">
                        <Col xl={6} md={6} sm={12}>
                            <Form.Label>Auditor's Remarks</Form.Label>
                            <Form.Control as="textarea" value={formData.overAllComment} onChange={(e) => { setAuditorComments(e.target.value); setFormData({ ...formData, overAllComment: e.target.value }) }} rows={3} placeholder="Write your message..." />
                        </Col>
                        <Col xl={6} md={6} sm={12}>
                            <Form.Label>Supervisor Remark</Form.Label>
                            <Form.Control
                                as="textarea"
                                value={formData.overAllRebuttal}
                                onChange={(e) => { setAuditorComments(e.target.value); setFormData({ ...formData, overAllRebuttal: e.target.value }) }}
                                rows={3}
                                placeholder="Write your message..."
                                disabled />
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
        </React.Fragment>
    );
}
