import React, { useEffect, useState } from "react";
import { Card, Form, Button } from "react-bootstrap";
import { getAuditDetails } from "../../../../application/selector/indexSelector";
import { useSelector } from "react-redux";

export default function SupervisorRemark() {
    const AuditDetailData= useSelector(getAuditDetails)
    const [auditComment, setAuditComment]= useState('')
    useEffect(()=>{
        setAuditComment(AuditDetailData?.overAllRebuttal)
    },[AuditDetailData])
    return (
        <React.Fragment>
            <Card.Header className="justify-content-between align-content-center ht-60">
                <h4 className="main-title fs-14 mb-0">Supervisor Remark</h4>
                <Button>Save</Button>
            </Card.Header>
            <Card.Body>
                <Form.Control as="textarea"  value={auditComment} name="auditComment" rows={2} placeholder="Write your message..." />
            </Card.Body>
        </React.Fragment>
        )
}