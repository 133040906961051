import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Offcanvas, Row } from "react-bootstrap";
import InventoryExcelView from "./inventory-excel-view";
import { useDispatch, useSelector } from "react-redux";
import { getDataSourceList, getProjectList } from "../../application/selector/indexSelector";
import Environment from "../../infrastructure/core/Environment";
import useAuth from "../../hooks/useAuth";
import * as XLSX from "xlsx";
import { AddDataSource } from "../../application/action/inventoryAction";

export default function UploadInventory(props) {
    const [showExcelView, setShowExcelView] = useState(false);
    const [previewData, setPreviewData] = useState(false);

    const [isNewSource, setIsNewSource] = useState(false);
    const { auth } = useAuth();
    const fileInputRef = useRef(null);
    const handleExcelView = (state) => {
        setShowExcelView(state)
    }
    const dispatch = useDispatch()
    const DataSourcesList = useSelector(getDataSourceList)
    const ProjectList = useSelector(getProjectList);

    const initialObject = {
        Description: "",
        DataSourceId: Environment.defaultValue,
        ProjectId: Environment.defaultValue,
        AddedBy: auth.id,
        ModifiedBy: auth.id,
        file: null
    }
    const [formValue, setFormaValue] = useState(initialObject)
    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormaValue((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) return; // If no file selected, do nothing
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Convert to 2D array
            setPreviewData(jsonData); // Set the parsed data for preview
        };
        reader.readAsArrayBuffer(file);

        setFormaValue({ ...formValue, file });
    };
    const [newSourceName, setNewSourceName] = useState('')
    const handleAddSource = (e) => {
        e.preventDefault()
        const data =
        {
            "name": newSourceName,
            "createdBy": auth.id,
            "modifiedBy": auth.id
        }

        dispatch(AddDataSource(data))
        setNewSourceName('')
        setIsNewSource(false)
    }
    const setNullForm = () => {
        setFormaValue(initialObject)
    }
    return (
        <React.Fragment>
            <InventoryExcelView setNullForm={setNullForm} formValue={formValue} data={previewData} show={showExcelView} closeFunction={handleExcelView} closeuploadModal={props.closeFunction} />
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-40">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Upload Inventory File</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col md="12">
                            <div className="mb-4">
                                <Form.Check type="switch" checked={isNewSource} name={isNewSource} onChange={(e) => { setIsNewSource(e.target.checked) }} className="fs-16" label="Add New Source" />
                            </div>
                        </Col>
                    </Row>

                    {isNewSource && (
                        <Row className="align-items-center">
                            <Col md="6">

                                <Form.Label className="me-2 mb-0">DataSource Name</Form.Label>
                                <div className="mb-4 d-flex align-items-center">
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Name"
                                        className="flex-grow-1"
                                        value={newSourceName}
                                        onChange={(e) => setNewSourceName(e.target.value)}
                                    />
                                    <Button
                                        type="button"
                                        variant="primary"
                                        className="ms-3"
                                        onClick={handleAddSource}
                                    >
                                        <span>ADD</span>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    )}
                    {/* <Row>
                        <Col md="7">
                            <div className="mb-4">
                                <Form.Label>Existing Project</Form.Label>
                                <Form.Select name="ProjectId" value={formValue.ProjectId} onChange={handleInputChange}>
                                    <option value={Environment.defaultValue}>Select Existing Project</option>
                                    {ProjectList?.map((ds) => (
                                        <option key={ds.id} value={ds.id}>
                                            {ds.projectName}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                        </Col>
                    </Row> */}
                    {!isNewSource && (
                        <Row>
                            <Col md="7">
                                <div className="mb-4">
                                    <Form.Label>Data Source</Form.Label>
                                    <Form.Select name="DataSourceId" value={formValue.DataSourceId} onChange={handleInputChange}>
                                        <option value={Environment.defaultValue}>Select Source</option>
                                        {DataSourcesList?.map((ds) => (
                                            <option key={ds.id} value={ds.id}>
                                                {ds.datasourceName}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                            </Col>
                        </Row>
                    )}
                    <Row>
                        <Col md="7">
                            <div className="mb-4">
                                <Form.Label>File Description</Form.Label>
                                <Form.Control name="Description" value={formValue.Description} onChange={handleInputChange} />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="7">
                            <div className="mb-4">
                            <a 
                            // href={workQueueExcel} 
                           // download="Template_WorkQueue.xlsx"
                            >
                <Button variant="outline-primary">
                  {" "}
                  <i className="ri-download-line fs-16 lh-1"></i> Download
                  Template
                </Button>
              </a>
 
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="7">
                            <div className="mb-4">
                                <Form.Label>Select File</Form.Label>
                                <Form.Control type="file" ref={fileInputRef} accept=".xls,.xlsx,.csv" onChange={handleFileUpload} />
                            </div>
                        </Col>
                    </Row>
                </Offcanvas.Body>

                <div className="offcanvas-footer justify-content-start">
                    <Button type="button" variant="primary" className="fs-14 me-2 d-flex align-items-center" onClick={() => handleExcelView(true)}>
                        <i className="ri-eye-line fs-16 lh-1 align-middle me-1"></i>
                        <span className="align-middle">Preview</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    )
}