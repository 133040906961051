import React, { useEffect, useRef, useState } from "react";
import Header from "../../layouts/Header";
import "./inventory.scss";
import DataTable from "react-data-table-component";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import FileRecords from "./file-records";
import ReactApexChart from "react-apexcharts";
//import inventoryListData from "../../Json/Inventory/inventory-list.json";
//import uploadLogData from "../../Json/Inventory/upload-log.json";
import UploadInventory from "./upload-inventory-modal";
import { GetAllHeaders, GetDataSourceList, GetInventoryDataListById, GetInventoryList } from "../../application/action/inventoryAction";
import { getDataSourceList, getInventoryList, getLoading } from "../../application/selector/indexSelector";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import PreviewInventoryData from "./PreviewInventoryData";
import { GetProjectList } from "../../application/action/projectAction";
import DataSourceView from "../AuditForm/data-source-view";
import TableShimmerLoader from "../_common/ShimmerTable";
import { UploadBridgeData } from "../../application/action/userAction";
import useAuth from "../../hooks/useAuth";
import LoadingBar from 'react-top-loading-bar';

export default function Inventory() {
    const { auth } = useAuth();
    const [data, setData] = useState([]);
    const [loadFileStatus, setLoadFileStatus] = useState(true);
    const [showFileRecords, setShowFileRecords] = useState(false);
    const dispatch = useDispatch()
    const DataSourcesList = useSelector(getDataSourceList);
    const UploadInventoryList = useSelector(getInventoryList);
    const { loading, value } = useSelector(getLoading);
    const fileInputRef = useRef(null);

    // HANDLE FILE UPLOAD FUNCTION
    const handleFileUpload = (event) => {
        const files = event.target.files;
        if (files.length === 0) return; // No file selected

        const formData = new FormData();
        formData.append("UploadedBy", auth.id);
        formData.append("File", files[0]); // Use the first file

        dispatch(UploadBridgeData(formData)); // Assuming `dispatch` is correctly set up

        //  setError(null);
        //  setFilesInfo([]);
    };

    // HANDLE BULK UPLOAD FUNCTION
    const handleBulkUpload = () => {
        fileInputRef.current.click();
    };
    useEffect(() => {
        dispatch(GetDataSourceList());
        dispatch(GetInventoryList());
        dispatch(GetAllHeaders());
        dispatch(GetProjectList());
    }, [])
    // CHARTS DATA
    const chart = {
        parentHeightOffset: 0,
        stacked: true,
        sparkline: {
            enabled: true
        }
    };

    const states = {
        hover: {
            filter: {
                type: 'none'
            }
        },
        active: {
            filter: {
                type: 'none'
            }
        }
    };

    const plotOptions = {
        bar: {
            columnWidth: '60%'
        },
    };

    const stroke = {
        curve: 'straight',
        lineCap: 'square'
    };

    const seriesOne = [{
        type: 'column',
        data: [[0, 0], [1, 0], [2, 5], [3, 10], [4, 6], [5, 10], [6, 15], [7, 18], [8, 7], [9, 11], [10, 13], [11, 15], [12, 13], [13, 7], [14, 5]]
    }, {
        type: 'column',
        data: data
    }];

    const optionOne = {
        chart: chart,
        states: states,
        colors: ['#506fd9', '#e5e9f2'],
        plotOptions: plotOptions,
        stroke: stroke,
        fill: { opacity: 1 },
        tooltip: { enabled: false }
    };

    const inventorycolumns = [
        {
            name: "DataSource",
            selector: (row) => row.datasourceName,
            sortable: true,
        },
        {
            name: "Inventory Total Count",
            selector: (row) => row.inventory_count,
            sortable: true,
        },
        {
            name: "Last Uploaded",
            selector: (row) => format(new Date(row.modifiedOn), "dd MMM yyyy hh:mm a"),
            sortable: true,
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex">
                    <Button variant="dark" className="btn-icon" onClick={() => handleDataSourceView(true, row)}><i className="ri-eye-line"></i></Button>
                </div>
            ),
            width: "100px"
        }
    ];

    // SEARCH FILTER TABLE DATA
    const [searchTerm, setSearchTerm] = useState('');
    const filteredDatasourceList = DataSourcesList?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const uploadLogcolumns = [
        {
            name: "Inventory Name",
            selector: (row) => row.inventoryName,
            sortable: true,
        },
        {
            name: "Upload By",
            selector: (row) => row.addedBy,
            sortable: true,
        },
        {
            name: "Total Cases",
            selector: (row) => (
                <p>{row.inventory_count}</p>
            ),
            sortable: true,
        },
        {
            name: "Added on",
            selector: (row) => format(new Date(row.addedOn), "dd MMM yyyy hh:mm a"),
            sortable: true,
        },
        {
            name: "Status",
            selector: (row) => (
                // <h6 className={`fs-14 fw-semibold ${row.status == "Processed" ? "text-success" : "text-danger"}`}>
                //     {row.status === "Uploading" && (
                //         <i className="ri-restart-line align-middle position-absolute start-0"></i>
                //     )}
                //     {row.status}</h6>
                <h6 className="fs-14 fw-semibold text-success"> Uploaded</h6>
            ),
            sortable: true,
        },
        {
            name: "Action",
            selector: (row) => (
                <div className="d-flex gap-2 align-items-center">
                    <Button variant="primary" className="ht-30 pt-0 pb-0" disabled>Recall</Button>
                    <Button variant="dark" className="btn-icon" onClick={() => handleInventoryPreviewData(row.id, true)}><i className="ri-eye-line"></i></Button>
                </div>
            ),

        }
    ];

    // SEARCH FILTER TABLE DATA
    const [searchUploadLog, setSearchUploadLog] = useState('');
    const filteredUploadLod = UploadInventoryList?.filter((item) =>
        Object.values(item).some((value) =>
            value && value.toString().toLowerCase().includes(searchUploadLog.toLowerCase())
        )
    );

    const [showUploadInventory, setShowUploadInventory] = useState(false);
    const handleUploadInventory = (state) => {
        setShowUploadInventory(state);
    }
    const [showInventoryData, setShowInventoryData] = useState(false);
    const [sourceData, setSourceData] = useState({});

    const handleInventoryPreviewData = (data, state) => {
        dispatch(GetInventoryDataListById(data))
        setShowInventoryData(state)
    }

    const [showDataSourceView, setShowDataSourceView] = useState(false);
    const handleDataSourceView = (state, data) => {
        setShowDataSourceView(state);
        setSourceData(data)
    };

     // PROGRESS TOP LOADER
     const loadingBarRef = useRef(null);
     useEffect(() => {
         if (loadingBarRef.current) {
             // Start the loading bar
             loadingBarRef.current.continuousStart();
     
             // Simulate a load completion or manage dynamically
             const timer = setTimeout(() => {
                 loadingBarRef.current.complete();
             }, 1000); // Adjust timing as needed
     
             // Cleanup timer
             return () => clearTimeout(timer);
         }
       }, []);

    return (
        <React.Fragment>
            <Header />
            <FileRecords show={showFileRecords} closeFunction={() => setShowFileRecords(false)} data={data} />
            <UploadInventory show={showUploadInventory} closeFunction={handleUploadInventory} />
            <PreviewInventoryData show={showInventoryData} closeFunction={() => setShowInventoryData(false)} />
            <DataSourceView show={showDataSourceView} closeFunction={handleDataSourceView} sourceData={sourceData} />
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="main main-app p-3 p-lg-4">
                <div className="d-md-flex align-items-center justify-content-between mb-4">
                    <div>
                        <h4 className="main-title mb-0">Inventory Summary</h4>
                    </div>
                </div>

                <Row className="g-3 mb-3">
                    <Col lg={4} md={4} sm={4}>
                        <Card className="card-one">
                            <Card.Body>
                                <Row>
                                    <Col xs={7}>
                                        <h3 className="card-value mb-1">4,608</h3>
                                        <label className="card-title fw-medium text-dark mb-1">Pending Cases</label>
                                        <span className="d-block text-muted fs-11 ff-secondary lh-4">Total cases pending for audit</span>
                                    </Col>
                                    <Col xs={5}>
                                        <ReactApexChart series={seriesOne} options={optionOne} type="bar" height={70} />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="card-one inventory-card-custom">
                            <Card.Body className="d-flex justify-content-center align-items-center">
                                <div>
                                    <h3 className="fs-14 mb-2 text-center">Upload Excel File</h3>
                                    <div>
                                        {/* <input type="file" style={{ display: "none" }} accept=".xls,.xlsx,.csv" />
                                        <Button variant="primary" className="d-flex alic-align-items-center gap-1">
                                            <i className="ri-chat-upload-line"></i> Inventory Dumb Upload
                                        </Button> */}
                                        <Button variant="primary" className="d-flex alic-align-items-center gap-1" onClick={() => handleUploadInventory(true)}>
                                            <i className="ri-chat-upload-line"></i> Upload File
                                        </Button>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col>
                        <Card className="card-one inventory-card-custom">
                            <Card.Body className="d-flex justify-content-center align-items-center">
                                <div>
                                    <h3 className="fs-14 mb-2 text-center">Upload Excel File</h3>
                                    <div>
                                        <Form.Control type="file" ref={fileInputRef} style={{ display: "none" }} accept=".xls,.xlsx,.csv" onChange={handleFileUpload} />
                                        {/* <input type="file" style={{ display: "none" }} accept=".xls,.xlsx,.csv" /> */}
                                        {/* <Button variant="primary" className="d-flex alic-align-items-center gap-1">
                                            <i className="ri-chat-upload-line"></i> Bridge Mapping Data Upload
                                        </Button> */}
                                        <Button variant="primary" onClick={handleBulkUpload}>
                                            <i className="ri-file-excel-2-line align-middle"></i> Upload Productivity Bridge
                                        </Button>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                <Card className="card-one mb-3">

                    <Card.Header className="align-items-center justify-content-between">
                        <h4 className="main-title fs-14 mb-0">DataSource List</h4>
                        <div className="custom-dropdown-wrapper">
                            <div className="custom-drop-down z-index-2 wt-300">
                                <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                                <Form.Control type="text" placeholder="Search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {filteredDatasourceList && filteredDatasourceList !== undefined && (
                            <DataTable
                                columns={inventorycolumns}
                                data={filteredDatasourceList}
                                fixedHeader
                                search={true}
                                highlightOnHover
                                pagination
                            ></DataTable>
                        )}
                        {!loading && filteredDatasourceList === undefined && (
                            <TableShimmerLoader colNames={inventorycolumns?.map((column) => column.name)} colsCount={1} />
                        )}
                        {loading &&
                            value === "DataSourceList" && filteredDatasourceList === undefined && (
                                <TableShimmerLoader colNames={inventorycolumns?.map((column) => column.name)} colsCount={1} />
                            )}
                    </Card.Body>
                </Card>

                <Card className="card-one">
                    <Card.Header className="align-items-center justify-content-between">
                        <h4 className="main-title fs-14 mb-0">Upload Log</h4>
                        <div className="custom-dropdown-wrapper">
                            <div className="custom-drop-down z-index-2 wt-300">
                                <span className="dropdown-icon bg-white"><i className="ri-search-line"></i></span>
                                <Form.Control type="text" placeholder="Search..." value={searchUploadLog} onChange={(e) => setSearchUploadLog(e.target.value)} />
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        {filteredUploadLod && filteredUploadLod !== undefined && (
                            <DataTable
                                columns={uploadLogcolumns}
                                data={filteredUploadLod}
                                fixedHeader
                                search={true}
                                highlightOnHover
                                pagination
                            ></DataTable>
                        )}
                        {!loading && filteredUploadLod === undefined && (
                            <TableShimmerLoader colNames={uploadLogcolumns?.map((column) => column.name)} colsCount={1} />
                        )}
                        {loading &&
                            value === "DataSourceList" && filteredUploadLod === undefined && (
                                <TableShimmerLoader colNames={uploadLogcolumns?.map((column) => column.name)} colsCount={1} />
                            )}
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    );
}