import React, { useEffect, useState } from "react";
import { Form, Offcanvas, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getSubErrorList } from "../../../../application/selector/indexSelector";
import useAuth from "../../../../hooks/useAuth";
import Environment from "../../../../infrastructure/core/Environment";
import { MapAuditFormError } from "../../../../application/action/projectAction";

export default function SubErrorModal(props) {
    const dispatch = useDispatch();
    const {auth} = useAuth()
    const SubErrorCategoryList = useSelector(getSubErrorList);
    const [selectedError, setSelectedError] = useState(null);
    // Initialize state to store form values
    const initialState = {
        mappingId: Environment.defaultValue,
        errorId: Environment.defaultValue,
        errorCategoryId: Environment.defaultValue,
        subErrorCategoryId: Environment.defaultValue,
        formId: Environment.defaultValue,
        points: 0,
        createdBy:Environment.defaultValue,
        modifiedBy: Environment.defaultValue
    }
    const [formData, setFormData] = useState(initialState);
    
useEffect(()=>{
    setFormData({
        ...formData,
        mappingId: props?.mappingId?props?.mappingId:Environment.defaultValue,
        errorCategoryId:props?.selectedCategory,
        formId:props?.data?.formId ,
        createdBy:auth.id,
        modifiedBy:auth.id


    })
},[props])
    const selectSubErrorOptions = SubErrorCategoryList?.map((data) => ({
        value: data.id,
        label: data.subErrorName
    }))



    // Handle SubError selection change
    const handleSubErrorChange = (selectedError) => {
        setSelectedError(selectedError)
        setFormData({
            ...formData,
            subErrorCategoryId: selectedError.value
        });
    };



   
    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        
        dispatch(MapAuditFormError([formData]));
        setFormData(initialState)  
        setSelectedError(null)
        props.closeFunction();
    };

    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-30">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Add Sub Error</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Row>
                        <Col>
                            <div className="mb-3">
                                <Form.Label>Sub Error</Form.Label>
                                <Select
                                    options={selectSubErrorOptions}
                                    value={selectedError}
                                    onChange={handleSubErrorChange} // Update state on change
                                />
                            </div>
                        </Col>
                    </Row>
                </Offcanvas.Body>
                <div className="offcanvas-footer justify-content-start">
                    <Button type="button" variant="primary" className="fs-14 me-2 d-flex align-items-center" onClick={handleSubmit}>
                        <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                        <span className="align-middle">Submit</span>
                    </Button>
                </div>
            </Offcanvas>
        </React.Fragment>
    );
}
