import React, { useEffect, useState, useRef } from "react";
import { Card, Tab, Tabs } from "react-bootstrap";
import ErrorType from "./ErrorType/error-type";
import ErrorCategories from "./ErrorCategories/error-categories";
import { useDispatch, useSelector } from "react-redux";
import { ErrorCategoryList, ErrorList, GetPointsList, SubErrorTypeList } from "../../../application/action/errorAction";
import SubError from "./SubErrorType/sub-error";
import POintsMaster from "./PointsMaster/POintsMaster";
import LoadingBar from 'react-top-loading-bar';


export default function ErrorMaster() {
    const dispatch = useDispatch();
    
    // HANDLE TAB SELECT TAB FUNCTION
    const [activeTab, setActiveTab] = useState('error_type_tab');
    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };
    useEffect(() => {
        dispatch(ErrorList())
        dispatch(ErrorCategoryList())
        dispatch(SubErrorTypeList())
        dispatch(GetPointsList())
    }, []);

    // PROGRESS TOP LOADER
    const loadingBarRef = useRef(null);
    useEffect(() => {
        if (loadingBarRef.current) {
            // Start the loading bar
            loadingBarRef.current.continuousStart();
    
            // Simulate a load completion or manage dynamically
            const timer = setTimeout(() => {
                loadingBarRef.current.complete();
            }, 3000); // Adjust timing as needed
    
            // Cleanup timer
            return () => clearTimeout(timer);
        }
      }, []);

    return (
        <React.Fragment>
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="d-md-flex align-items-center justify-content-between mb-4">
                <div>
                    <h4 className="fs-16">Error Master</h4>
                </div>
            </div>

            <div className="common-tabs">
                <Card className="card-one">
                    <Card.Body>
                        <Tabs id="custom-styled-tabs" activeKey={activeTab} onSelect={handleTabSelect} className="common-tabs">
                            <Tab eventKey="error_categories_tab" title="Error Categories" tabClassName="custom-tab-header fw-semibold">
                                <div className="custom-tab-body">
                                    <ErrorCategories />
                                </div>
                            </Tab>
                            <Tab eventKey="error_type_subcategories_tab" title="Sub Error Type" tabClassName="custom-tab-header fw-semibold">
                                <div className="custom-tab-body">
                                    <SubError />
                                </div>
                            </Tab>
                            <Tab eventKey="error_type_tab" title="Error Type" tabClassName="custom-tab-header fw-semibold">
                                <div className="custom-tab-body">
                                    <ErrorType />
                                </div>
                            </Tab>

                            <Tab eventKey="points_tab" title="Points Master" tabClassName="custom-tab-header fw-semibold">
                                <div className="custom-tab-body">
                                    <POintsMaster />
                                </div>
                            </Tab>
                        </Tabs>
                    </Card.Body>
                </Card>
            </div>
        </React.Fragment>
    )
}