import React, { useState } from 'react'
import { Button, Col, Form, Offcanvas, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import useAuth from '../../../../hooks/useAuth';
import Environment from '../../../../infrastructure/core/Environment';
import { AddPoints } from '../../../../application/action/errorAction';

function CreatePointsMaster(props) {
    const dispatch = useDispatch();
    const { setAuth, auth } = useAuth();

    const initialState = {
        name: "",
        description: "",
        value: 0,
        status: Environment.defaultValue,
        createdBy: auth.id,
        modifiedBy: auth.id,
    };
    const [formData, setFormData] = useState(initialState);
    // Handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(AddPoints(formData))
        setFormData(initialState)
        props.closeFunction()
    }
    return (
        <Offcanvas
            show={props.show}
            onHide={props.closeFunction}
            placement="end"
            className="w-40"
        >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="fs-16 text-dark">
                    ADD
                </Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body>
                <Row>
                    <Col md={8}>
                        <div className="mb-4">
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Title"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <div className="mb-4">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter Description"
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={8}>
                        <div className="mb-4">
                            <Form.Label>Value</Form.Label>
                            <Form.Control
                                type="number"
                                min={1}
                                placeholder="Enter Value"
                                name="value"
                                value={formData.value}
                                onChange={handleInputChange}
                            />
                        </div>
                    </Col>
                </Row>
            </Offcanvas.Body>

            <div className="offcanvas-footer justify-content-start">
                <Button
                    type="submit"
                    variant="primary"
                    className="fs-14 me-2 d-flex align-items-center"
                    onClick={handleSubmit}
                >
                    <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                    <span className="align-middle">Create </span>
                </Button>
            </div>
        </Offcanvas>
    )
}

export default CreatePointsMaster