import React from "react";
import { Offcanvas, Card } from "react-bootstrap";

export default function ChangeLogModal(props) {
    return (
        <React.Fragment>
            <Offcanvas show={props.show} onHide={props.closeFunction} placement="end" className="w-30">
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="fs-16 text-dark">Change Log</Offcanvas.Title>
                </Offcanvas.Header>

                <Offcanvas.Body>
                    <div className="activity-log-timeline">
                        <ul className="activity-group">
                            {[
                                {
                                    "title": "Audit Created by XYZ Auditor",
                                    "time": "10:00 AM",
                                    "status": "success"
                                },
                                {
                                    "title": "Audit Published Successfully",
                                    "time": "10:30 AM",
                                    "status": "success"
                                },
                                {
                                    "title": "TL (Atik Shaikh) Submited a Rebuttal",
                                    "time": "10:45 AM",
                                    "des": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
                                    "status": "danger"
                                },
                                {
                                    "title": "Auditor (Aamir Sayyed) Rejected the Rebuttal",
                                    "time": "11:15 AM",
                                    "des": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
                                    "status": "danger"
                                },
                                {
                                    "title": "ATA Submited by Asfaq Shaikh",
                                    "time": "12:45 AM",
                                    "status": "success"
                                }].map((item, index) => (
                                    <li className={`activity-item ${item.status === "success" ? "success-class" : "danger-class"}`} key={index}>
                                        <h5 className="fs-14 mb-1">{item.title}</h5>

                                        {item.des && (
                                            <div className="product-wrapper mb-1">
                                                <Card.Body>{item.des}</Card.Body>
                                            </div>
                                        )}

                                        <h6 className="text-secondary fs-xs ms-auto">{item.time}</h6>
                                    </li>
                                ))}
                        </ul>
                    </div>
                </Offcanvas.Body>
            </Offcanvas>
        </React.Fragment>
    )
}