import React, { useRef, useEffect, useState } from "react";
import { Badge, Button, Card, Col, Row, Form, Tabs, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Chart } from "chart.js";
import Header from "../../../../../layouts/Header";
import AddErrorsModal from "../../../AuditDetails/add-errors-modal";
//import AuditForm from "./audit-form";
import LoadingBar from 'react-top-loading-bar';

export default function StartAudit() {
    const chartRef1 = useRef(null);
    const score = 90; // Overall score percentage

    // USEEFFECT
    useEffect(() => {
        const ctx = chartRef1.current.getContext('2d');

        // Create the gauge chart
        const chart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                datasets: [
                    {
                        data: [25, 50, 10], // Red 25%, Green 25%, Gray 50%
                        backgroundColor: ['red', 'green', '#E0E0E0'], // Red and Green for equal 25% sections
                        borderWidth: 0,
                        circumference: 180, // Half circle
                        rotation: -90, // Start from the top
                    },
                ],
            },
            options: {
                responsive: true,
                plugins: {
                    tooltip: { enabled: false }, // Disable tooltips
                    legend: { display: false }, // Disable legend
                    title: {
                        display: true,
                        text: '',
                    },
                },
                cutout: '80%', // Control thickness of the gauge
                aspectRatio: 2,
            },
        });

        // Draw the score percentage in the center
        const drawScoreText = () => {
            ctx.clearRect(0, 0, chart.width, chart.height); // Clear previous drawings
            chart.update(); // Update the chart to draw it again
            ctx.save();
            ctx.font = '20px Arial';
            ctx.fillStyle = '#000'; // Color of the score text
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(`Overall Score: ${score}%`, chart.width / 2, chart.height / 2);
            ctx.restore();
        };

        drawScoreText();

        // Cleanup function to destroy the chart when the component unmounts
        return () => {
            chart.destroy();
        };
    }, [score]); // Adding score as a dependency to re-render if it changes


    // HANDLE FILE UPLOAD FUNCTION
    const fileInputRef = useRef(null);
    const handleFileUpload = (event) => {

    };

    // HANDLE BULK UPLOAD FUNCTION
    const handleBulkUpload = () => {
        fileInputRef.current.click();
    };

    // HANDLE ADD ERROR FUNCTION
    const [showAddError, setShowAddError] = useState(false);
    const handleAddError = (state) => {
        setShowAddError(state);
    }

    // HANDLE TAB SELECT FUNCTION
    const [activeTab, setActiveTab] = useState('sample_data_tab');
    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };

     // PROGRESS TOP LOADER
     const loadingBarRef = useRef(null);
     useEffect(() => {
         if (loadingBarRef.current) {
             // Start the loading bar
             loadingBarRef.current.continuousStart();
     
             // Simulate a load completion or manage dynamically
             const timer = setTimeout(() => {
                 loadingBarRef.current.complete();
             }, 3000); // Adjust timing as needed
     
             // Cleanup timer
             return () => clearTimeout(timer);
         }
       }, []);

    return (
        <React.Fragment>
            <Header />
            <AddErrorsModal show={showAddError} closeFunction={handleAddError} />
            <LoadingBar color="#506fd9" ref={loadingBarRef} shadow={true} />
            <div className="main main-app manage-item-page p-3 p-lg-4">
                <Row className="mb-4 align-items-center">
                    <Col>
                        <div>
                            <ol className="breadcrumb fs-sm mb-1">
                                <li className="breadcrumb-item">
                                    <Link to="/QaAudit/manage">QA Audit</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Audit #124578</li>
                            </ol>
                            <h4 className="main-title mb-0">Manage Item</h4>
                        </div>
                    </Col>
                    <Col>
                        <div className="d-flex gap-2 justify-content-end mt-md-0 align-items-center">
                            <div>
                                <Form.Control type="file" ref={fileInputRef} style={{ display: "none" }} accept=".xls,.xlsx,.csv" onChange={handleFileUpload} />
                                <Button variant="dark" className="fs-14 text-nowrap gap-2" onClick={handleBulkUpload}>
                                    <i className="ri-file-excel-2-line fs-18 me-2 align-middle"></i>
                                    <span className="align-middle">Upload Document</span>
                                </Button>
                            </div>

                            <Button variant="primary" className="fs-14 text-nowrap gap-2">
                                <i className="ri-save-line fs-18 me-2 align-middle"></i>
                                <span className="align-middle">Save Changes</span>
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Row className="g-3">
                    <Col md={8}>
                        <Card className="card-one mb-3 h-auto">
                            <Card.Body>
                                <div className="mb-4">
                                    <h2 className="mb-2"># <b>414575</b> <Badge className="fs-14" bg="warning" pill>Open</Badge></h2>
                                    <h3 className="fs-14">Project Name <i className="ri-arrow-right-s-line align-middle"></i> <span>Audit Form Name</span></h3>
                                </div>

                                <div className="d-flex flex-wrap gap-5">
                                    <div>
                                        <h6 className="fs-14 fw-bold mb-1">Atik Shaikh</h6>
                                        <Form.Label>Agent Name</Form.Label>
                                    </div>
                                    <div>
                                        <h6 className="fs-14 fw-bold mb-1">Aamir Sayyed</h6>
                                        <Form.Label>Supervisor Name</Form.Label>
                                    </div>
                                    <div>
                                        <h6 className="fs-14 fw-bold mb-1">24587</h6>
                                        <Form.Label>LMIS Case ID</Form.Label>
                                    </div>
                                    <div>
                                        <h6 className="fs-14 fw-bold mb-1">12/09/2024</h6>
                                        <Form.Label>Action Date</Form.Label>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>

                        <Row>

                            <Col lg={4} md={4} sm={12}>
                                <Card className="card-one">
                                    <Card.Body>
                                        <Tabs id="custom-styled-tabs" activeKey={activeTab} onSelect={handleTabSelect} className="common-tabs">
                                            <Tab eventKey="sample_data_tab" title="Sample Data Field" tabClassName="custom-tab-header fw-semibold">
                                                <div className="custom-tab-body pt-3">
                                                    <h3 className="fs-14 fw-semibold mb-3">Sample Data Field</h3>
                                                    <ul className="list-group list-group-flush">
                                                        {[
                                                            {
                                                                "field_name": "Field 1",
                                                                "field_data": "Sample Data Here",
                                                            },
                                                            {
                                                                "field_name": "Field 2",
                                                                "field_data": "Sample Data Here"
                                                            },
                                                            {
                                                                "field_name": "Field 3",
                                                                "field_data": "Sample Data Here"
                                                            },
                                                            {
                                                                "field_name": "Field 4",
                                                                "field_data": "Sample Data Here"
                                                            },
                                                            {
                                                                "field_name": "Field 5",
                                                                "field_data": "Sample Data Here"
                                                            },
                                                            {
                                                                "field_name": "Field 6",
                                                                "field_data": "Sample Data Here"
                                                            }].map((item, index) => (
                                                                <li className="list-group-item d-flex justify-content-between" key={index}>
                                                                    <span className="fs-14">{item.field_name}</span>
                                                                    <span className="fs-14">{item.field_data}</span>
                                                                </li>
                                                            ))}
                                                    </ul>
                                                </div>
                                            </Tab>

                                            <Tab eventKey="upload_document_tab" title="Upload Documents" tabClassName="custom-tab-header fw-semibold">
                                                <div className="custom-tab-body pt-3">
                                                <h3 className="fs-14 fw-semibold mb-3">Upload Document List</h3>
                                                </div>
                                            </Tab>
                                        </Tabs>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={8} md={8} sm={12}>
                                <Card className="card-one">
                                    <Card.Body>
                                        <h3 className="fs-14 fw-semibold mb-3">Activity Log</h3>

                                        <div className="activity-log-timeline">
                                            <ul className="activity-group">
                                                {[
                                                    {
                                                        "title": "Audit Created by XYZ Auditor",
                                                        "time": "10:00 AM",
                                                        "status": "success"
                                                    },
                                                    {
                                                        "title": "Audit Published Successfully",
                                                        "time": "10:30 AM",
                                                        "status": "success"
                                                    },
                                                    {
                                                        "title": "TL (Atik Shaikh) Submited a Rebuttal",
                                                        "time": "10:45 AM",
                                                        "des": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
                                                        "status": "danger"
                                                    },
                                                    {
                                                        "title": "Auditor (Aamir Sayyed) Rejected the Rebuttal",
                                                        "time": "11:15 AM",
                                                        "des": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,",
                                                        "status": "danger"
                                                    },
                                                    {
                                                        "title": "ATA Submited by Asfaq Shaikh",
                                                        "time": "12:45 AM",
                                                        "status": "success"
                                                    }].map((item, index) => (
                                                        <li className={`activity-item ${item.status === "success" ? "success-class" : "danger-class"}`} key={index}>
                                                            <h5 className="fs-16 mb-1">{item.title}</h5>

                                                            {item.des && (
                                                                <div className="product-wrapper mb-1">
                                                                    <Card.Body>{item.des}</Card.Body>
                                                                </div>
                                                            )}

                                                            <h6 className="text-secondary fs-xs ms-auto">{item.time}</h6>
                                                        </li>
                                                    ))}
                                            </ul>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4}>
                        <Card className="card-one h-auto">
                            <Card.Body>
                                <div className="position-relative d-flex align-items-center justify-content-center mb-4" style={{ maxWidth: '350px', margin: 'auto' }}>
                                    <canvas ref={chartRef1} />
                                    <div className="overall-score-view">
                                        <h6>Overall Score</h6>
                                        <h2 className="fw-bold fs-36">{score}%</h2>
                                    </div>
                                </div>

                                <Row className="justify-content-around">
                                    <Col>
                                        <div className="d-flex mb-3 justify-content-around align-items-center">
                                            <div className="d-flex align-content-center gap-2">
                                                <span className="wt-20 ht-20 bg-danger d-block"></span>
                                                <span>Fatal Error</span>
                                            </div>
                                            <div><h6 className="fw-bold fs-14 text-dark">0</h6></div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="d-flex justify-content-around align-items-center">
                                            <div className="d-flex align-content-center gap-2">
                                                <span className="wt-20 ht-20 bg-warning d-block"></span>
                                                <span>Non-Fatal Error</span>
                                            </div>
                                            <div><h6 className="fw-bold fs-14 text-dark">1</h6></div>
                                        </div>
                                    </Col>
                                </Row>


                                <hr />

                                {/* OVERALL SCORE DATA */}
                                {[
                                    {
                                        "name": "Data Integrity Error",
                                        "low": 25,
                                        "high": 75
                                    },
                                    {
                                        "name": "Authorization & Security Errors",
                                        "low": 30,
                                        "high": 70
                                    },
                                    {
                                        "name": "Identification & Matching Errors",
                                        "low": 0,
                                        "high": 100
                                    },
                                    {
                                        "name": "Operational & Processing Errors",
                                        "low": 100,
                                        "high": 0
                                    },
                                    {
                                        "name": "Overall",
                                        "low": 100,
                                        "high": 0
                                    }
                                ].map((item, index) => (
                                    <Row className="mb-2 align-items-center" key={index}>
                                        <Col>
                                            <h3 className={`fs-14 text-dark ${item.name === "Overall" ? "fw-bold" : ""}`}>{item.name}</h3>
                                        </Col>
                                        <Col>
                                            <div className="overall-score-progress">
                                                <div className="progress" style={{ maxWidth: "60%" }}>
                                                    <div className="progress-bar bg-danger" style={{ width: `${item.low}%` }}>
                                                        <span>{item.low}%</span>
                                                    </div>
                                                    <div className="progress-bar bg-success" style={{ width: `${item.high}%` }}>
                                                        <span>{item.high}%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                ))}


                                {/* AUDITOR REMARK */}
                                <div className="mt-5">
                                    <div className="d-flex align-items-center justify-content-between mb-3">
                                        <h3 className="fs-14">List of Errors</h3>
                                        <Button variant="danger" className="text-uppercase fs-12" onClick={() => handleAddError(true)}>Add Error</Button>
                                    </div>


                                    <div className="mb-4">
                                        <div className="product-wrapper mb-2">
                                            <Card.Body className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className="ri-error-warning-line fs-24 text-warning"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h3 className="fs-14 fw-bold mb-1">Unauthorized Access 
                                                            {/* <Badge bg="success" className="ms-1" pill>PASS</Badge> */}
                                                            </h3>
                                                        <h6 className="fs-12 fw-semibold mb-2">Data Integrity Errors</h6>
                                                        <p className="fs-14">This is a sample comment</p>
                                                    </div>
                                                </div>

                                               
                                            </Card.Body>
                                        </div>

                                        <div className="product-wrapper">
                                            <Card.Body className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <i className="ri-error-warning-line fs-24 text-warning"></i>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3">
                                                        <h3 className="fs-14 fw-bold mb-1">Unauthorized Access 
                                                            {/* <Badge bg="success" className="ms-1" pill>PASS</Badge> */}
                                                            </h3>
                                                        <h6 className="fs-12 fw-semibold mb-2">Data Integrity Errors</h6>
                                                        <p className="fs-14">This is a sample comment</p>
                                                    </div>
                                                </div>

                                                {/* <Badge bg="success" className="fs-16" pill>PASS</Badge> */}
                                            </Card.Body>
                                        </div>
                                    </div>

                                    <h3 className="fs-14 mb-3">Auditor Remark</h3>
                                    <Form.Control as="textarea" rows={4} placeholder="Enter Your comments" />
                                    {/* <div className="no-errors">
                                        <Image className="wt-200" src={noError} />
                                    </div> */}

                                </div>
                            </Card.Body>

                            {/* <Card.Footer className="d-flex gap-3">
                                <Button variant="success" className="flex-fill">Access Audit</Button>
                                <Button variant="danger" className="flex-fill">Reject and Rebuttal</Button>
                            </Card.Footer> */}
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    )
}